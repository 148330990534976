<template>
  <div class="pr-2 pl-2 my-1">
    <div class="d-flex flex-column align-start pt-3">
      <div v-if="isValidRowNew" class="d-flex flex-row">
        <span class="overline grey--text">Valor Nuevo</span>
      </div>
      <div v-if="isValidRowNew" class="d-flex flex-row mt-3 ms-5">
        <v-text-field
          label="Hectáreas asignadas"
          dense
          type="number"
          outlined
          readonly
          filled
          hide-details
          :class="getClass(rowNew.assignedHectares == rowOld.assignedHectares)"
          class="managrx-input mr-2"
          :value="rowNew.assignedHectares"
          v-if="rowNew.assignedHectares !== undefined"
        ></v-text-field>
        <v-text-field
          label="Mes Siembra"
          dense
          outlined
          readonly
          filled
          hide-details
          :class="getClass(getDate(rowNew.sowDate) == getDate(rowOld.sowDate))"
          type="date"
          class="managrx-input mr-2"
          v-if="rowNew.sowDate !== undefined"
          :value="getDate(rowNew.sowDate)"
        ></v-text-field>

        <v-text-field
          label="Mes Cosecha"
          dense
          outlined
          readonly
          filled
          hide-details
          :class="
            getClass(getDate(rowNew.harvestDate) == getDate(rowOld.harvestDate))
          "
          type="date"
          class="managrx-input mr-2"
          v-if="rowNew.harvestDate !== undefined"
          :value="getDate(rowNew.harvestDate)"
        ></v-text-field>

        <v-text-field
          label="Mes Ingreso"
          dense
          readonly
          outlined
          filled
          hide-details
          :class="
            getClass(getDate(rowNew.incomeDate) == getDate(rowOld.incomeDate))
          "
          type="date"
          class="managrx-input mr-2"
          v-if="rowNew.incomeDate !== undefined"
          :value="getDate(rowNew.incomeDate)"
        ></v-text-field>

        <v-text-field
          label="Rinde esperado"
          dense
          readonly
          outlined
          filled
          hide-details
          :class="getClass(rowNew.forwardYield == rowOld.forwardYield)"
          type="number"
          class="managrx-input mr-2"
          v-if="rowNew.forwardYield !== undefined"
          :value="rowNew.forwardYield"
        ></v-text-field>
        <v-text-field
          label="Cultivo Compartido (0-100)%"
          dense
          readonly
          outlined
          filled
          hide-details
          :class="
            getClass(
              rowNew.fixedSharecroppingPercent ==
                rowOld.fixedSharecroppingPercent
            )
          "
          type="number"
          class="managrx-input mr-2"
          v-if="rowNew.fixedSharecroppingPercent !== undefined"
          :value="rowNew.fixedSharecroppingPercent * 100"
        ></v-text-field>
        <v-select
          label="Aparcería Fija"
          :items="[
            { name: 'Entrega KG', value: true },
            { name: 'No entrega KG', value: false },
          ]"
          item-text="name"
          item-value="value"
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(
              rowNew.isFixedSharecroppingHandedOver ==
                rowOld.isFixedSharecroppingHandedOver
            )
          "
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowNew.isFixedSharecroppingHandedOver !== undefined"
          :value="rowNew.isFixedSharecroppingHandedOver"
        ></v-select>
        <v-select
          label="Aparcería Variable"
          :items="[
            { name: 'Entrega KG', value: true },
            { name: 'No entrega KG', value: false },
          ]"
          item-text="name"
          item-value="value"
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(
              rowNew.isVariableSharecroppingHandedOver ==
                rowOld.isVariableSharecroppingHandedOver
            )
          "
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowNew.isVariableSharecroppingHandedOver !== undefined"
          :value="rowNew.isVariableSharecroppingHandedOver"
        ></v-select>
        <v-text-field
          label="Arrendamiento (0-100)%"
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(
              rowNew.rentPercentApplication == rowOld.rentPercentApplication
            )
          "
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowNew.rentPercentApplication !== undefined"
          :value="rowNew.rentPercentApplication * 100"
        ></v-text-field>
        <v-text-field
          label="Precio Flete"
          dense
          type="number"
          outlined
          readonly
          filled
          hide-details
          :class="getClass(rowNew.freightCostTn == rowOld.freightCostTn)"
          class="managrx-input mr-2"
          v-if="rowNew.freightCostTn !== undefined"
          :value="rowNew.freightCostTn"
        ></v-text-field>
        <v-select
          outlined
          class="managrx-input mr-2"
          dense
          label="Estado Flete"
          open-on-clear
          persistent-hint
          filled
          hide-details
          :class="
            getClass(
              rowNew.isActualFreightCostTn == rowOld.isActualFreightCostTn
            )
          "
          readonly
          :items="actualsItems"
          :value="rowNew.isActualFreightCostTn"
          item-value="value"
          item-text="name"
          v-if="rowNew.isActualFreightCostTn !== undefined"
        >
        </v-select>
        <v-text-field
          label="Cosecha Min"
          dense
          type="number"
          outlined
          readonly
          filled
          hide-details
          :class="getClass(rowNew.minHarvestCost == rowOld.minHarvestCost)"
          class="managrx-input mr-2"
          v-if="rowNew.minHarvestCost !== undefined"
          :value="rowNew.minHarvestCost"
        ></v-text-field>
        <v-text-field
          label="Cosecha Max"
          dense
          type="number"
          outlined
          readonly
          filled
          hide-details
          :class="getClass(rowNew.maxHarvestCost == rowOld.maxHarvestCost)"
          class="managrx-input mr-2"
          v-if="rowNew.maxHarvestCost !== undefined"
          :value="rowNew.maxHarvestCost"
        ></v-text-field>
        <v-text-field
          label="Porcentaje de Cosecha"
          dense
          type="number"
          outlined
          readonly
          filled
          hide-details
          :class="
            getClass(rowNew.harvestPercentCost == rowOld.harvestPercentCost)
          "
          class="managrx-input mr-2"
          v-if="rowNew.harvestPercentCost !== undefined"
          :value="rowNew.harvestPercentCost * 100"
        ></v-text-field>
        <v-select
          outlined
          class="managrx-input mr-2"
          dense
          label="Estado Cosecha"
          open-on-clear
          persistent-hint
          filled
          hide-details
          :class="
            getClass(rowNew.isActualHarvestCost == rowOld.isActualHarvestCost)
          "
          readonly
          :items="actualsItems"
          :value="rowNew.isActualHarvestCost"
          item-value="value"
          item-text="name"
          v-if="rowNew.isActualHarvestCost !== undefined"
        >
        </v-select>
        <v-text-field
          label="Seguros"
          v-if="rowNew.insuranceCategories !== undefined"
          :value="
            rowNew.insuranceCategories == 'set()'
              ? '-'
              : rowNew.insuranceCategories
          "
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(rowNew.insuranceCategories == rowOld.insuranceCategories)
          "
          readonly
          class="managrx-input mr-2"
        >
        </v-text-field>
        <v-text-field
          label="Seguro (0-100)%"
          dense
          outlined
          filled
          hide-details
          :class="getClass(rowNew.insuranceRate == rowOld.insuranceRate)"
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowNew.insuranceRate !== undefined"
          :value="rowNew.insuranceRate * 100"
        ></v-text-field>
        <v-text-field
          label="Mes de materialización del seguro"
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(
              getDate(rowNew.insuranceMaterializationDate) ==
                getDate(rowOld.insuranceMaterializationDate)
            )
          "
          readonly
          type="date"
          class="managrx-input mr-2"
          v-if="rowNew.insuranceMaterializationDate !== undefined"
          :value="getDate(rowNew.insuranceMaterializationDate)"
        ></v-text-field>
        <v-text-field
          label="Calidad Grano (0-100)%"
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(rowNew.grainQualityFactor == rowOld.grainQualityFactor)
          "
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowNew.grainQualityFactor !== undefined"
          :value="rowNew.grainQualityFactor * 100"
        ></v-text-field>
        <v-text-field
          label="Dir. Técnica (0-100)%"
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(rowNew.technicalDirection == rowOld.technicalDirection)
          "
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowNew.technicalDirection !== undefined"
          :value="rowNew.technicalDirection * 100"
        ></v-text-field>
        <v-text-field
          label="Entregador"
          dense
          outlined
          filled
          hide-details
          :class="getClass(rowNew.saleHandOver == rowOld.saleHandOver)"
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowNew.saleHandOver !== undefined"
          :value="rowNew.saleHandOver"
        ></v-text-field>
        <v-text-field
          label="Comisiones (0-100)%"
          dense
          outlined
          filled
          hide-details
          :class="getClass(rowNew.saleComission == rowOld.saleComission)"
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowNew.saleComission !== undefined"
          :value="rowNew.saleComission * 100"
        ></v-text-field
        ><v-text-field
          label="Impuestos de venta (0-100)%"
          dense
          outlined
          filled
          hide-details
          :class="getClass(rowNew.saleTax == rowOld.saleTax)"
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowNew.saleTax !== undefined"
          :value="rowNew.saleTax * 100"
        ></v-text-field>
      </div>
      <div v-if="isValidRowOld" class="d-flex flex-row mt-3">
        <span class="overline grey--text">Valor Anterior</span>
      </div>
      <div v-if="isValidRowOld" class="d-flex flex-row mt-3 ms-5">
        <v-text-field
          label="Hectáreas asignadas"
          dense
          type="number"
          outlined
          readonly
          filled
          hide-details
          :class="getClass(rowNew.assignedHectares == rowOld.assignedHectares)"
          class="managrx-input mr-2"
          :value="rowOld.assignedHectares"
          v-if="rowOld.assignedHectares !== undefined"
        ></v-text-field>
        <v-text-field
          label="Mes Siembra"
          dense
          outlined
          readonly
          filled
          hide-details
          :class="getClass(getDate(rowNew.sowDate) == getDate(rowOld.sowDate))"
          type="date"
          class="managrx-input mr-2"
          v-if="rowOld.sowDate !== undefined"
          :value="getDate(rowOld.sowDate)"
        ></v-text-field>

        <v-text-field
          label="Mes Cosecha"
          dense
          outlined
          readonly
          filled
          hide-details
          :class="
            getClass(getDate(rowNew.harvestDate) == getDate(rowOld.harvestDate))
          "
          type="date"
          class="managrx-input mr-2"
          v-if="rowOld.harvestDate !== undefined"
          :value="getDate(rowOld.harvestDate)"
        ></v-text-field>

        <v-text-field
          label="Mes Ingreso"
          dense
          readonly
          outlined
          filled
          hide-details
          :class="
            getClass(getDate(rowNew.incomeDate) == getDate(rowOld.incomeDate))
          "
          type="date"
          class="managrx-input mr-2"
          v-if="rowOld.incomeDate !== undefined"
          :value="getDate(rowOld.incomeDate)"
        ></v-text-field>

        <v-text-field
          label="Rinde esperado"
          dense
          readonly
          outlined
          filled
          hide-details
          :class="getClass(rowNew.forwardYield == rowOld.forwardYield)"
          type="number"
          class="managrx-input mr-2"
          v-if="rowOld.forwardYield !== undefined"
          :value="rowOld.forwardYield"
        ></v-text-field>
        <v-text-field
          label="Cultivo Compartido (0-100)%"
          dense
          readonly
          outlined
          filled
          hide-details
          :class="
            getClass(
              rowNew.fixedSharecroppingPercent ==
                rowOld.fixedSharecroppingPercent
            )
          "
          type="number"
          class="managrx-input mr-2"
          v-if="rowOld.fixedSharecroppingPercent !== undefined"
          :value="rowOld.fixedSharecroppingPercent * 100"
        ></v-text-field>
        <v-select
          label="Aparcería Fija"
          :items="[
            { name: 'Entrega KG', value: true },
            { name: 'No entrega KG', value: false },
          ]"
          item-text="name"
          item-value="value"
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(
              rowNew.isFixedSharecroppingHandedOver ==
                rowOld.isFixedSharecroppingHandedOver
            )
          "
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowOld.isFixedSharecroppingHandedOver !== undefined"
          :value="rowOld.isFixedSharecroppingHandedOver"
        ></v-select>
        <v-select
          label="Aparcería Variable"
          :items="[
            { name: 'Entrega KG', value: true },
            { name: 'No entrega KG', value: false },
          ]"
          item-text="name"
          item-value="value"
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(
              rowNew.isVariableSharecroppingHandedOver ==
                rowOld.isVariableSharecroppingHandedOver
            )
          "
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowOld.isVariableSharecroppingHandedOver !== undefined"
          :value="rowOld.isVariableSharecroppingHandedOver"
        ></v-select>
        <v-text-field
          label="Arrendamiento (0-100)%"
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(
              rowNew.rentPercentApplication == rowOld.rentPercentApplication
            )
          "
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowOld.rentPercentApplication !== undefined"
          :value="rowOld.rentPercentApplication * 100"
        ></v-text-field>
        <v-text-field
          label="Precio Flete"
          dense
          type="number"
          outlined
          readonly
          filled
          hide-details
          :class="getClass(rowNew.freightCostTn == rowOld.freightCostTn)"
          class="managrx-input mr-2"
          v-if="rowOld.freightCostTn !== undefined"
          :value="rowOld.freightCostTn"
        ></v-text-field>
        <v-select
          outlined
          class="managrx-input mr-2"
          dense
          label="Estado Flete"
          open-on-clear
          persistent-hint
          filled
          hide-details
          :class="
            getClass(
              rowNew.isActualFreightCostTn == rowOld.isActualFreightCostTn
            )
          "
          readonly
          :items="actualsItems"
          :value="rowOld.isActualFreightCostTn"
          item-value="value"
          item-text="name"
          v-if="rowOld.isActualFreightCostTn !== undefined"
        >
        </v-select>
        <v-text-field
          label="Cosecha Min"
          dense
          type="number"
          outlined
          readonly
          filled
          hide-details
          :class="getClass(rowNew.minHarvestCost == rowOld.minHarvestCost)"
          class="managrx-input mr-2"
          v-if="rowOld.minHarvestCost !== undefined"
          :value="rowOld.minHarvestCost"
        ></v-text-field>
        <v-text-field
          label="Cosecha Max"
          dense
          type="number"
          outlined
          readonly
          filled
          hide-details
          :class="getClass(rowNew.maxHarvestCost == rowOld.maxHarvestCost)"
          class="managrx-input mr-2"
          v-if="rowOld.maxHarvestCost !== undefined"
          :value="rowOld.maxHarvestCost"
        ></v-text-field>
        <v-text-field
          label="Porcentaje de Cosecha"
          dense
          type="number"
          outlined
          readonly
          filled
          hide-details
          :class="
            getClass(rowNew.harvestPercentCost == rowOld.harvestPercentCost)
          "
          class="managrx-input mr-2"
          v-if="rowOld.harvestPercentCost !== undefined"
          :value="rowOld.harvestPercentCost * 100"
        ></v-text-field>
        <v-select
          outlined
          class="managrx-input mr-2"
          dense
          label="Estado Cosecha"
          open-on-clear
          persistent-hint
          filled
          hide-details
          :class="
            getClass(rowNew.isActualHarvestCost == rowOld.isActualHarvestCost)
          "
          readonly
          :items="actualsItems"
          :value="rowOld.isActualHarvestCost"
          item-value="value"
          item-text="name"
          v-if="rowOld.isActualHarvestCost !== undefined"
        >
        </v-select>
        <v-text-field
          label="Seguros"
          v-if="rowOld.insuranceCategories !== undefined"
          :value="
            rowOld.insuranceCategories == 'set()'
              ? '-'
              : rowOld.insuranceCategories
          "
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(rowNew.insuranceCategories == rowOld.insuranceCategories)
          "
          readonly
          class="managrx-input mr-2"
        >
        </v-text-field>
        <v-text-field
          label="Seguro (0-100)%"
          dense
          outlined
          filled
          hide-details
          :class="getClass(rowNew.insuranceRate == rowOld.insuranceRate)"
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowOld.insuranceRate !== undefined"
          :value="rowOld.insuranceRate * 100"
        ></v-text-field>
        <v-text-field
          label="Mes de materialización del seguro"
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(
              getDate(rowNew.insuranceMaterializationDate) ==
                getDate(rowOld.insuranceMaterializationDate)
            )
          "
          readonly
          type="date"
          class="managrx-input mr-2"
          v-if="rowOld.insuranceMaterializationDate !== undefined"
          :value="getDate(rowOld.insuranceMaterializationDate)"
        ></v-text-field>
        <v-text-field
          label="Calidad Grano (0-100)%"
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(rowNew.grainQualityFactor == rowOld.grainQualityFactor)
          "
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowOld.grainQualityFactor !== undefined"
          :value="rowOld.grainQualityFactor * 100"
        ></v-text-field>
        <v-text-field
          label="Dir. Técnica (0-100)%"
          dense
          outlined
          filled
          hide-details
          :class="
            getClass(rowNew.technicalDirection == rowOld.technicalDirection)
          "
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowOld.technicalDirection !== undefined"
          :value="rowOld.technicalDirection * 100"
        ></v-text-field>
        <v-text-field
          label="Entregador"
          dense
          outlined
          filled
          hide-details
          :class="getClass(rowNew.saleHandOver == rowOld.saleHandOver)"
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowOld.saleHandOver !== undefined"
          :value="rowOld.saleHandOver"
        ></v-text-field>
        <v-text-field
          label="Comisiones (0-100)%"
          dense
          outlined
          filled
          hide-details
          :class="getClass(rowNew.saleComission == rowOld.saleComission)"
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowOld.saleComission !== undefined"
          :value="rowOld.saleComission * 100"
        ></v-text-field
        ><v-text-field
          label="Impuestos de venta (0-100)%"
          dense
          outlined
          filled
          hide-details
          :class="getClass(rowNew.saleTax == rowOld.saleTax)"
          readonly
          type="number"
          class="managrx-input mr-2"
          v-if="rowOld.saleTax !== undefined"
          :value="rowOld.saleTax * 100"
        ></v-text-field>
      </div>
    </div>
  </div>
</template>

<style scoped>
.background-muted {
  background-color: #fafafa;
  opacity: 0.3;
}
.v-text-field {
  width: 150px;
  max-width: 300px;
}
.managrx-input {
  font-weight: 400 !important;
}
.rounded-start {
  border-radius: 5px 0px 0px 5px;
}

.rounded-end {
  border-radius: 0px 5px 5px 0px;
}
.light-text-style {
  font-weight: 100 !important;
}
.bold-text-style {
  font-weight: 900 !important;
}
</style>
<script>
import { ACTUALS_ITEMS } from "../../managrx/constants";
export default {
  name: "Globals",
  props: {
    rowNew: Object,
    rowOld: Object,
  },
  methods: {
    getClass(isSameValue) {
      if (this.isValidRowNew && this.isValidRowOld) {
        if (isSameValue) {
          return "light-text-style";
        } else {
          return "bold-text-style";
        }
      } else {
        return "";
      }
    },
    getDate(dateIsoStr) {
      if (dateIsoStr) {
        return dateIsoStr?.split("T")?.[0];
      }
    },
  },
  computed: {
    isValidRowNew() {
      return Object.keys(this.rowNew).length > 0;
    },
    isValidRowOld() {
      return Object.keys(this.rowOld).length > 0;
    },
  },
  data() {
    return {
      actualsItems: ACTUALS_ITEMS,
    };
  },
};
</script>
