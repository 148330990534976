<template>
  <v-form ref="form" class="pr-2 pl-2">
    <div class="d-flex">
      <v-text-field
        label="Familia"
        outlined
        dense
        filled
        class="managrx-input mr-2"
        :value="newRow.family"
        readonly
      ></v-text-field>

      <v-autocomplete
        ref="mainSelect"
        v-model="select"
        @change="changeCostItemSelect"
        open-on-clear
        :items="selectableCostItems"
        return-object
        :rules="required"
        item-text="value"
        item-value="idx"
        label="Producto"
        dense
        outlined
        class="managrx-input mr-2"
      ></v-autocomplete>

      <v-select
        ref="periodSelect"
        v-model="selectedPeriod"
        @change="changePeriodSelect"
        open-on-clear
        :items="sliceInputCostPeriods"
        return-object
        item-text="name"
        item-value="id"
        label="Periodo"
        dense
        outlined
        class="managrx-input mr-2"
      ></v-select>
      <v-text-field
        label="Hectáreas Asignadas"
        outlined
        dense
        class="managrx-input mr-2"
        v-model="assignedHectares"
        readonly
        filled
      ></v-text-field>
      <v-text-field
        label="(0-100)% App"
        outlined
        dense
        append-icon="mdi-percent-outline"
        :rules="percentAppRules"
        type="number"
        class="managrx-input mr-2"
        v-model.number="newRow.percentApplicationAsInt"
        @change="
          (e) => {
            this.computeSubtotal();
          }
        "
      ></v-text-field>

      <v-text-field
        label="Unidades x Hectáreas"
        @change="
          (e) => {
            this.computeSubtotal();
          }
        "
        v-model.number="newRow.units"
        type="number"
        :rules="unitsRules"
        outlined
        dense
        class="managrx-input mr-2"
      ></v-text-field>
      <v-text-field
        label="Precio"
        @change="
          (e) => {
            this.computeSubtotal();
          }
        "
        outlined
        dense
        type="number"
        class="managrx-input mr-2"
        :value="newRow.price"
        readonly
        filled
      ></v-text-field>
      <v-text-field
        label="Unidad de Medida"
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
        v-model="newRow.unitMeasure"
      ></v-text-field>
      <v-text-field
        label="Subtotal"
        @change="
          (e) => {
            this.computeSubtotal();
          }
        "
        outlined
        dense
        class="managrx-input mr-2"
        readonly
        filled
        v-model="newRow.subTotal"
      ></v-text-field>
    </div>
    <div class="d-flex">
      <v-text-field
        class="managrx-input mr-2"
        outlined
        dense
        label="Fecha de uso"
        v-model="newRow.dateUsed"
        :rules="dateUsedRules"
        type="date"
        :min="campaignStartDate.split('T')[0]"
        :max="campaignEndDate.split('T')[0]"
        @blur="validateDate(newRow.dateUsed, 'dateUsed')"
      ></v-text-field>
      <v-select
        class="managrx-input mr-2"
        :items="[
          { value: 'Fixed Date', idx: 1 },
          { value: 'Policy Based', idx: 2 },
        ]"
        :rules="required"
        item-text="value"
        item-value="idx"
        label="Opción de pago"
        dense
        outlined
        @change="changePaymentOption"
        v-model="paymentOption"
        :readonly="!(hasPaymentPolicy && hasFinancing)"
        :filled="!(hasPaymentPolicy && hasFinancing)"
      >
      </v-select>
      <v-text-field
        class="managrx-input mr-2"
        outlined
        dense
        label="Fecha de pago"
        v-model="newRow.datePaid"
        v-if="paymentOption === 1 && !hasPaymentPolicy"
        :min="campaignStartDate.split('T')[0]"
        :max="campaignEndDate.split('T')[0]"
        @blur="validateDate(newRow.datePaid, 'datePaid')"
        type="date"
      ></v-text-field>
      <v-select
        v-if="paymentOption === 2 && hasPaymentPolicy"
        ref="paymentPolicy"
        v-model="newRow.paymentPolicy"
        @change="changePaymentPolicySelect"
        open-on-clear
        :rules="required"
        :items="financialPolicies"
        return-object
        item-text="name"
        item-value="id"
        label="Política de pago"
        dense
        outlined
        class="managrx-input mr-2"
      ></v-select>
      <v-select
        ref="interestSelect"
        v-model="selectedInterest"
        @change="changeInterestSelect"
        open-on-clear
        :items="interestRates"
        return-object
        item-text="name"
        item-value="id"
        label="Financiación"
        dense
        outlined
        class="managrx-input mr-2"
        :readonly="!hasFinancing"
        :filled="hasPaymentPolicy"
      ></v-select>
      <v-btn
        depressed
        color="primary white--text"
        class="mr-2"
        @click="saveClick"
      >
        <v-icon> mdi-floppy</v-icon>
      </v-btn>
      <v-btn depressed color="info" class="mr-2" @click="resetNewRow">
        <v-icon> mdi-restart</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
import { validateRequestObject } from "../../managrx/services";
import { postRequest } from "@/managrx/serverCall";

import useSharedSliceCosts from "@/mixins/useSharedSliceCosts";
import useFindables from "@/mixins/useFindables";
import { NEW_PROJECT_SLICE_COST_INPUT_FORM_RULES } from "@/managrx/formsFieldsRules.js";
import { validateForm } from "../../managrx/utils";
export default {
  name: "NewSliceCostInput",
  props: {
    assignedHectares: Number,
    activitySliceId: Number,
    title: String,
    region: Number,
    activity: Number,
    campaign: Number,
    families: Array,
    hasPaymentPolicy: Boolean,
    hasFinancing: Boolean,
    sliceProductsList: Object,
    sliceInputCostPeriods: Array,
    campaignStartDate: String,
    campaignEndDate: String,
    financialPolicies: Array,
    interestRates: Array,
  },
  mixins: [useSharedSliceCosts, useFindables],
  mounted() {
    if (this.hasPaymentPolicy) {
      this.paymentOption = 2;
    }
  },
  computed: {
    percentAppAsDecimal() {
      return this.newRow.percentApplicationAsInt / 100;
    },
    required() {
      return [(v) => !!v || "Campo obligatorio"];
    },
    percentAppRules() {
      if (!this.newRow.percentApplicationAsInt) {
        return [];
      }
      let fieldRules = NEW_PROJECT_SLICE_COST_INPUT_FORM_RULES?.percentApp;
      let detail = validateForm(
        this.newRow.percentApplicationAsInt,
        fieldRules
      );
      return detail;
    },
    unitsRules() {
      if (!this.newRow.units) {
        return [];
      }
      let fieldRules = NEW_PROJECT_SLICE_COST_INPUT_FORM_RULES?.units;
      let detail = validateForm(this.newRow.units, fieldRules);
      return detail;
    },
    dateUsedRules() {
      if (!this.newRow.dateUsed) {
        return [];
      }
      let fieldRules = NEW_PROJECT_SLICE_COST_INPUT_FORM_RULES?.dateUsed;
      let detail = validateForm(this.newRow.dateUsed, fieldRules);
      return detail;
    },
    datePaidRules() {
      if (!this.newRow.datePaid) {
        return [];
      }
      let fieldRules = NEW_PROJECT_SLICE_COST_INPUT_FORM_RULES?.dateUsed;
      let detail = validateForm(this.newRow.datePaid, fieldRules);
      return detail;
    },
  },
  methods: {
    validateDate(date, key) {
      let selectedDate;
      const minDate = new Date(this.campaignStartDate);
      const maxDate = new Date(this.campaignEndDate);
      if (new Date(date)) {
        selectedDate = new Date(date);
        if (selectedDate < minDate) {
          this.newAS[key] = this.campaignStartDate.split("T")[0];
        } else if (selectedDate > maxDate) {
          this.newAS[key] = this.campaignEndDate.split("T")[0];
        }
      }
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        let obj = {
          activitySliceId: this.activitySliceId,
          listedDirectSliceCostItemId: this.newRow.listedDirectSliceCostItemId,
          percentApplication: this.percentAppAsDecimal,
          costInputPeriodId: this.newRow.costInputPeriodId,
          unitsPerHectare: this.newRow.units,
          dateUsed: new Date(this.newRow.dateUsed).toISOString(),
        };

        if (this.paymentOption === 1) {
          obj["datePaid"] = new Date(this.newRow.datePaid).toISOString();
        } else {
          obj["paymentPolicyId"] = this.newRow.paymentPolicy.id;
        }

        // \\console.log("obj", obj);
        let validate = [
          "activitySliceId",
          "listedDirectSliceCostItemId",
          "price",
          "unitsPerHectare",
          "dateUsed",
          "percentApplication",
          "paymentPolicyId",
        ];

        let fieldToText = {
          activitySliceId: "ID de actividad",
          listedDirectSliceCostItemId: "Producto/Servicio",
          price: "Precio",
          unitsPerHectare: "Unidades x Hectáreas",
          dateUsed: "Fecha de uso",
          percentApplication: "Porcentaje de Aplicación",
          paymentPolicyId: "Politica de Pago",
        };

        let isValid = validateRequestObject(obj, validate);

        if (!isValid.success) {
          let msg = `${fieldToText[isValid?.key]} no puede ser 0 o nulo`;
          let notif = {
            success: false,
            data: {
              type: "alert",
              text: msg,
            },
          };
          this.$store.dispatch("setNewNotification", notif);
        } else {
          let response = await postRequest(
            "/assigned_direct_slice_cost_item",
            obj
          );

          if (!response.success) {
            this.$store.dispatch("setNewNotification", response);
          } else {
            this.$emit("rowSave", response.data);
          }
          this.resetNewRow();
        }
      }
    },
    resetNewRow() {
      this.$refs.form.resetValidation();
      if (this.hasPaymentPolicy) {
        this.paymentOption = 2;
      } else {
        this.paymentOption = 1;
      }
      this.checkbox = true;
      this.select = null;
      this.paymentPolicySelect = null;
      this.selectedPeriod = null;
      this.selectedInterest = null;
      this.newRow = {
        id: null,
        paymentPolicy: {},
        costInputPeriodId: 1,
        listedDirectSliceCostItemId: null,
        price: null,
        percentApplicationAsInt: null,
        interestRateId: null,
        family: null,
        units: null,
        unitMeasure: null,
        subTotal: null,
        dateUsed: "2021-11-05",
        datePaid: "2021-11-05",
        rate: null,
      };
    },
  },

  data() {
    return {
      paymentOption: 1,
      checkbox: true,
      select: null,
      paymentPolicySelect: null,
      selectedPeriod: null,
      selectedInterest: null,
      newRow: {
        id: null,
        paymentPolicy: {},
        costInputPeriodId: 1,
        listedDirectSliceCostItemId: null,
        price: null,
        percentApplicationAsInt: null,
        interestRateId: null,
        family: null,
        units: null,
        unitMeasure: null,
        subTotal: null,
        dateUsed: "2021-11-05",
        datePaid: "2021-11-05",
        rate: null,
      },
    };
  },
};
</script>
