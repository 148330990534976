<template>
  <div class="pr-2 pl-2 my-1 mb-2">
    <div class="d-flex flex-column py-2" v-if="editMode">
      <div
        class="d-flex pt-3 justify-center flex-column flex-lg-row flex-xl-row"
      >
        <div class="mr-2 mt-1">
          <v-tooltip v-if="isActual" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="" v-bind="attrs" v-on="on"
                >mdi-alpha-r-circle</v-icon
              >
            </template>
            <span>Realizado</span>
          </v-tooltip>
          <v-tooltip v-if="isProyected" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="" v-bind="attrs" v-on="on"
                >mdi-alpha-p-circle-outline</v-icon
              >
            </template>
            <span>Proyectado</span>
          </v-tooltip>
          <v-tooltip v-if="isNotActual" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="" v-bind="attrs" v-on="on"
                >mdi-alpha-n-circle-outline</v-icon
              >
            </template>
            <span>No realizado</span>
          </v-tooltip>
        </div>
        <v-text-field
          label="Familia"
          outlined
          dense
          class="managrx-input mr-2"
          v-model="newRow.family"
          readonly
          filled
        ></v-text-field>

        <v-autocomplete
          v-if="!isSandGFamily"
          ref="mainSelect"
          :value="select"
          @change="changeCostItemSelect"
          open-on-clear
          :items="selectableCostItems"
          return-object
          item-text="value"
          item-value="idx"
          label="Insumo"
          dense
          outlined
          class="managrx-input mr-2"
        ></v-autocomplete>

        <v-select
          v-if="!isSandGFamily"
          ref="periodSelect"
          :value="selectedPeriod"
          @change="changePeriodSelect"
          open-on-clear
          :items="sliceInputCostPeriods"
          return-object
          item-text="name"
          item-value="id"
          label="Periodo"
          dense
          outlined
          class="managrx-input mr-2"
        ></v-select>

        <v-text-field
          v-if="!isSandGFamily"
          label="Hectáreas Asignadas"
          outlined
          dense
          class="managrx-input mr-2"
          v-model="assignedHectares"
          readonly
          filled
        ></v-text-field>

        <v-text-field
          v-if="!isSandGFamily"
          label="(0-100)% App"
          append-icon="mdi-percent-outline"
          @change="
            (e) => {
              this.computeSubtotal();
            }
          "
          outlined
          dense
          type="number"
          class="managrx-input mr-2"
          v-model.number="newRow.percentApplicationAsInt"
        ></v-text-field>

        <v-text-field
          v-if="!isSandGFamily"
          label="Unidades x Hectáreas"
          @change="
            (e) => {
              this.computeSubtotal();
            }
          "
          v-model.number="newRow.units"
          type="number"
          outlined
          dense
          class="managrx-input mr-2"
        ></v-text-field>

        <v-text-field
          label="Precio"
          @change="
            (e) => {
              this.computeSubtotal();
            }
          "
          outlined
          dense
          type="number"
          class="managrx-input mr-2"
          readonly
          filled
          v-model.number="newRow.price"
        ></v-text-field>

        <v-text-field
          v-if="!isSandGFamily"
          label="Unidad de Medida"
          outlined
          dense
          class="managrx-input mr-2"
          readonly
          filled
          v-model="newRow.unitMeasure"
        ></v-text-field>

        <v-text-field
          label="Subtotal"
          @change="
            (e) => {
              this.computeSubtotal();
            }
          "
          outlined
          dense
          class="managrx-input mr-2"
          readonly
          filled
          v-model="newRow.subTotal"
        ></v-text-field>
      </div>

      <div class="d-flex">
        <v-text-field
          class="managrx-input mr-2"
          outlined
          dense
          label="Fecha de uso"
          v-model="newRow.dateUsed"
          type="date"
          :min="campaignStartDate.split('T')[0]"
          :max="campaignEndDate.split('T')[0]"
          @blur="validateDate1(newRow.dateUsed, 'dateUsed')"
        ></v-text-field>

        <v-select
          v-if="!isSandGFamily"
          class="managrx-input mr-2"
          :items="[
            { value: 'Fixed Date', idx: 1 },
            { value: 'Policy Based', idx: 2 },
          ]"
          item-text="value"
          item-value="idx"
          label="Opción de pago"
          dense
          outlined
          @change="changePaymentOption"
          v-model="paymentOption"
          :readonly="!(hasPaymentPolicy && hasFinancing)"
          :filled="!(hasPaymentPolicy && hasFinancing)"
        >
        </v-select>
        <v-text-field
          class="managrx-input mr-2"
          outlined
          dense
          label="Fecha de pago"
          v-model="datePaid"
          v-if="paymentOption === 1 && !hasPaymentPolicy"
          type="date"
          :min="campaignStartDate.split('T')[0]"
          :max="campaignEndDate.split('T')[0]"
          @blur="validateDate2(datePaid)"
        ></v-text-field>
        <v-select
          v-if="paymentOption === 2 && !isSandGFamily && hasPaymentPolicy"
          ref="paymentPolicySelect"
          v-model="newRow.paymentPolicy"
          @change="changePaymentPolicySelect"
          open-on-clear
          :items="financialPolicies"
          return-object
          item-text="name"
          item-value="id"
          label="Política de pago"
          dense
          outlined
          class="managrx-input mr-2"
        ></v-select>

        <v-select
          v-if="!isSandGFamily"
          ref="interestSelect"
          :value="newRow.interestRateId"
          @change="changeInterestSelect"
          open-on-clear
          :items="interestRates"
          return-object
          item-text="name"
          item-value="id"
          label="Financiación"
          dense
          outlined
          class="managrx-input mr-2"
          :readonly="!hasFinancing"
          :filled="hasPaymentPolicy"
        ></v-select>
        <div class="d-flex flex-row">
          <v-select
            outlined
            class="managrx-input mr-2"
            dense
            label="Estado"
            open-on-clear
            persistent-hint
            :hint="
              newRow.isActual !== row.isActual ? 'El estado fue modificado' : ''
            "
            :items="actualsItems"
            v-model="newRow.isActual"
            item-value="value"
            item-text="name"
          >
          </v-select>
          <!-- <v-tooltip v-if="isProyected" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="rounded-start"
                :depressed="true"
                color=""
                v-bind="attrs"
                v-on="on"
                @click="updateActualStatus(true)"
              >
                <v-icon color="success">mdi-tag-check-outline</v-icon>
              </v-btn>
            </template>
            <span>Realizado</span>
          </v-tooltip>
          <v-tooltip v-if="isActual || isNotActual" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="rounded-start"
                :depressed="true"
                color=""
                v-bind="attrs"
                v-on="on"
                @click="updateActualStatus(null)"
              >
                <v-icon color="warning">mdi-tag-plus-outline</v-icon>
              </v-btn>
            </template>
            <span>Proyectado</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="rounded-0"
                :depressed="true"
                color=""
                :disabled="isNotActual"
                v-bind="attrs"
                v-on="on"
                @click="updateActualStatus(false)"
              >
                <v-icon color="error">mdi-tag-minus-outline</v-icon>
              </v-btn>
            </template>
            <span>No realizado</span>
          </v-tooltip> -->
        </div>
        <v-btn
          depressed
          color="primary white--text"
          class="mr-2"
          @click="saveClick"
        >
          <v-icon> mdi-floppy</v-icon>
        </v-btn>

        <v-btn
          depressed
          color="info white--text"
          class="mr-2"
          @click="backClick"
        >
          Atrás
        </v-btn>
      </div>

      <v-divider class="mb-2"> </v-divider>
    </div>
    <div class="d-flex flex-row align-start pa-2" v-if="!editMode">
      <div class="mt-1 mr-2">
        <v-tooltip v-if="isActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on"
              >mdi-alpha-r-circle</v-icon
            >
          </template>
          <span>Realizado</span>
        </v-tooltip>
        <v-tooltip v-if="isProyected" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on"
              >mdi-alpha-p-circle-outline</v-icon
            >
          </template>
          <span>Proyectado</span>
        </v-tooltip>
        <v-tooltip v-if="isNotActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :class="{ 'background-muted': isNotActual }"
              class="background-small-muted"
              color=""
              v-bind="attrs"
              v-on="on"
              >mdi-alpha-n-circle-outline</v-icon
            >
          </template>
          <span>No realizado</span>
        </v-tooltip>
      </div>
      <v-text-field
        label="Familia"
        outlined
        dense
        filled
        hide-details
        class="managrx-input mr-2"
        :class="{ 'background-muted': isNotActual }"
        v-model="row.family"
        readonly
      ></v-text-field>
      <v-text-field
        :value="getDirectSliceCostItemNameById(row.listedDirectSliceCostItemId)"
        label="Producto"
        class="managrx-input mr-2"
        outlined
        hide-details
        :class="{ 'background-muted': isNotActual }"
        dense
        readonly
        filled
        @click="goToDirectSliceCostItem(row.directSliceCostItemId)"
      ></v-text-field>
      <v-text-field
        label="Periodo"
        outlined
        dense
        class="managrx-input mr-2"
        :class="{ 'background-muted': isNotActual }"
        v-model="row.periodType"
        hide-details
        readonly
        filled
        v-if="row.periodType"
      ></v-text-field>

      <v-text-field
        label="Dosis"
        outlined
        hide-details
        dense
        :class="{ 'background-muted': isNotActual }"
        class="managrx-input mr-2"
        readonly
        :value="displayedDosisString"
        filled
      ></v-text-field>

      <v-text-field
        class="managrx-input mr-2"
        label="Fecha de uso"
        :class="{ 'background-muted': isNotActual }"
        v-model="row.dateUsed"
        outlined
        dense
        hide-details
        readonly
        filled
      ></v-text-field>

      <v-text-field
        :value="row.paymentPolicyName"
        label="Política Pago"
        outlined
        hide-details
        dense
        :class="{ 'background-muted': isNotActual }"
        class="managrx-input mr-2"
        readonly
        filled
        v-if="row.paymentPolicyName"
      ></v-text-field>

      <v-text-field
        :value="row.datePaid"
        label="Fecha de pago"
        outlined
        :class="{ 'background-muted': isNotActual }"
        hide-details
        dense
        class="managrx-input mr-2"
        readonly
        filled
        v-if="row.datePaid"
      ></v-text-field>

      <v-select
        ref="interestSelect"
        :value="row.interestRateId"
        @change="changeInterestSelect"
        open-on-clear
        :items="interestRates"
        return-object
        item-text="name"
        :class="{ 'background-muted': isNotActual }"
        hide-details
        item-value="id"
        label="Financiación"
        dense
        outlined
        filled
        readonly
        class="managrx-input mr-2"
        v-if="row.interestRateId"
      ></v-select>
      <v-btn
        :depressed="true"
        v-if="!deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="editClick"
      >
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="deleteMode"
        :depressed="true"
        color="error"
        class="mr-2 white--text lighten-1"
        @click="confirmDeleteClick"
      >
        Confirmar borrado
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
      <v-btn
        depressed
        color="error  white--text "
        class="mr-2"
        @click="deleteClick"
        v-if="!deleteMode"
      >
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.background-muted {
  background-color: #fafafa;
  opacity: 0.3;
}
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
.rounded-start {
  border-radius: 5px 0px 0px 5px;
}

.rounded-end {
  border-radius: 0px 5px 5px 0px;
}
</style>

<script>
import useSharedSliceCosts from "@/mixins/useSharedSliceCosts";
import useFindables from "@/mixins/useFindables";
import { validateRequestObject } from "@/managrx/services";
import { properties } from "../sharedProps/sliceCostItem";
import { ACTUALS_ITEMS } from "../../managrx/constants";

export default {
  name: "AbstractSliceCostInput",
  props: { ...properties },
  mixins: [useSharedSliceCosts, useFindables],
  computed: {
    isActual() {
      // true
      return this.row?.isActual === true;
    },
    isProyected() {
      // null it means proyected
      return this.row?.isActual === null;
    },
    isNotActual() {
      // Not proyected nor actual
      return this.row?.isActual === false;
    },
    percentAppAsDecimal() {
      return this.newRow.percentApplicationAsInt / 100;
    },
    displayedDosisString() {
      return this.row.unitsPerHectare + " " + this.row.unitMeasure + "/ha";
    },
    isSandGFamily() {
      return this.row?.family == "Seguimiento y Control";
    },
  },
  methods: {
    updateActualStatus(status) {
      this.$emit("updateActualStatus", status);
    },
    validateDate1(date, key) {
      let selectedDate;
      const minDate = new Date(this.campaignStartDate);
      const maxDate = new Date(this.campaignEndDate);
      if (new Date(date)) {
        selectedDate = new Date(date);
        if (selectedDate < minDate) {
          this.newAS[key] = this.campaignStartDate.split("T")[0];
        } else if (selectedDate > maxDate) {
          this.newAS[key] = this.campaignEndDate.split("T")[0];
        }
      }
    },
    validateDate2(date) {
      let selectedDate;
      const minDate = new Date(this.campaignStartDate);
      const maxDate = new Date(this.campaignEndDate);
      if (new Date(date)) {
        selectedDate = new Date(date);
        if (selectedDate < minDate) {
          this.datePaid = this.campaignStartDate.split("T")[0];
        } else if (selectedDate > maxDate) {
          this.datePaid = this.campaignEndDate.split("T")[0];
        }
      }
    },
    goToDirectSliceCostItem(directSliceCostItemId) {
      let routeData = this.$router.resolve({
        name: "ListedCostItems",
        params: { id: directSliceCostItemId },
      });
      window.open(routeData.href, "_blank");
    },
    deleteClick() {
      this.deleteMode = true;
    },
    async saveClick() {
      // let assignedDirectSliceCostItemId =this.newRow.assignedDirectSliceCostItemId;

      let payload = {
        listedDirectSliceCostItemId: this.newRow.listedDirectSliceCostItemId,
        percentApplication: this.percentAppAsDecimal,
        costInputPeriodId: this.newRow.costInputPeriodId,
        unitsPerHectare: this.newRow.units,
        dateUsed: new Date(this.newRow.dateUsed).toISOString(),
        datePaid: null,
        paymentPolicyId: null,
      };

      if (this.row.isActual !== this.newRow.isActual) {
        payload.isActual = this.newRow.isActual;
      }

      // "No Financiar"
      if (this.paymentOption === 1) {
        payload.datePaid = new Date(this.datePaid).toISOString();
        payload.paymentPolicyId = null;
      }

      // "Financed" Cost Item
      if (this.paymentOption === 2) {
        payload.paymentPolicyId = this.newRow?.paymentPolicy?.id;
        payload.datePaid = null;
      }

      payload.interestRateId = this.newRow.interestRateId;

      let validate = [
        "activitySliceId",
        "listedDirectSliceCostItemId",
        "price",
        "unitsPerHectare",
        "dateUsed",
      ];
      let fieldToText = {
        activitySliceId: "ID de actividad",
        listedDirectSliceCostItemId: "Producto/Servicio",
        price: "Precio",
        unitsPerHectare: "Unidades x Hectáreas",
        dateUsed: "Fecha de uso",
      };

      let isValid = validateRequestObject(payload, validate);

      if (!isValid.success) {
        let msg = `${fieldToText[isValid?.key]} no puede ser 0 o nulo`;
        let notif = {
          success: false,
          data: {
            type: "alert",
            text: msg,
          },
        };
        this.$emit("setNewNotification", notif);
      } else {
        this.$emit("saveClick", payload);
      }
      this.editMode = false;
    },
    async confirmDeleteClick() {
      let assignedDirectSliceCostItemId =
        this.row.assignedDirectSliceCostItemId;
      this.$emit("confirmDeleteRow", assignedDirectSliceCostItemId);
      this.deleteMode = false;
    },
    editClick() {
      if (this.row.datePaid) {
        this.datePaid = this.row.datePaid;
      } else {
        this.paymentOption = 2;
        this.paymentPolicyId = this.row.paymentPolicyId;
      }

      this.select = this.getDirectSliceCostItemNameById(
        this.row.listedDirectSliceCostItemId
      );
      this.paymentPolicy = this.getPaymentPolicyNameById(
        this.row.paymentPolicyId
      );
      this.selectedPeriod = this.getPeriodIdByName(this.row.periodType);

      this.newRow = {
        ...this.row,
        percentApplicationAsInt: this.row.percentApplication * 100,
        paymentPolicy: {
          name: this.paymentPolicy,
          id: this.row.paymentPolicyId,
        },
      };
      this.computeSubtotal();
      this.editMode = true;
    },
    backClick() {
      this.editMode = false;
      this.deleteMode = false;
    },
    resetNewRow() {
      this.$refs.mainSelect.reset();
      this.$refs.periodSelect.reset();
      this.newRow = {
        id: 1,
        isActual: null,
        price: 0,
        family: "",
        units: 1,
        unitMeasure: "kg",
        subTotal: 0,
        dateUsed: "2021-11-05",
        select: "",
        percentApplicationAsInt: 5,
        costInputPeriodId: 1,
      };
    },
  },

  async mounted() {
    if (this.hasPaymentPolicy == null) {
      this.hasPaymentPolicy = false;
    }

    if (this.hasFinancing == null) {
      this.hasFinancing = false;
    }
    this.computeSubtotal();
  },
  data() {
    return {
      actualsItems: ACTUALS_ITEMS,
      paymentOption: 1,
      paymentPolicy: null,
      select: null,
      editMode: false,
      deleteMode: false,
      selectedPeriod: null,
      newRow: {
        percentApplicationAsInt: 5,
        costInputPeriodId: 1,
      },
    };
  },
};
</script>
