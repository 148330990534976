<template>
  <AbstractNewTonBasedCost
    :fixedShareCroppingValue="fixedShareCroppingValue"
    :variableShareCroppingValue="variableShareCroppingValue"
    :assignedHectares="singleActivitySlice.assignedHectares"
    :activitySliceId="singleActivitySlice.id"
    :dynamicYield="singleActivitySlice.summary.dynamicYield"
    :otherTonCostItems="otherTonCostItems"
    @rowSave="saveClick"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { postRequest } from "@/managrx/serverCall";
import AbstractNewTonBasedCost from "../Abstract/AbstractNewTonBasedCost.vue";

export default {
  name: "NewTonBasedCost",
  props: { assignedHectares: Number, activitySliceId: Number, title: String },
  components: { AbstractNewTonBasedCost },
  methods: {
    async saveClick(payload) {
      let response = await postRequest(
        "/assigned_other_ton_cost_item",
        payload
      );

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("rowSave", payload);
      }
    },
  },
  computed: {
    ...mapGetters(["singleActivitySlice", "otherTonCostItems"]),
    fixedShareCroppingValue() {
      return this.singleActivitySlice?.isFixedSharecroppingHandedOver
        ? this.singleActivitySlice?.fixedSharecroppingPercent
        : 0;
    },
    variableShareCroppingValue() {
      return this.singleActivitySlice?.isVariableSharecroppingHandedOver
        ? this.singleActivitySlice?.summary?.variableSharecroppingCosts
        : 0;
    },
  },
};
</script>
