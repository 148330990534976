<template>
  <div class="pr-2 pl-2 my-1">
    <div class="d-flex flex-column align-start pt-3">
      <div v-if="isValidRowNew" class="d-flex flex-rowNew">
        <span class="overline grey--text">Valor Nuevo</span>
      </div>
      <div v-if="isValidRowNew" class="d-flex flex-rowNew mt-3 ms-5">
        <v-text-field
          :class="getClass(rowNew.floor == rowOld.floor)"
          :value="rowNew.floor"
          v-if="rowNew.floor !== undefined"
          label="Mínimo"
          type="number"
          outlined
          dense
          hide-details
          class="managrx-input mr-2"
          readonly
          filled
        ></v-text-field>
        <v-text-field
          :class="getClass(rowNew.ceiling == rowOld.ceiling)"
          :value="rowNew.ceiling"
          v-if="rowNew.ceiling !== undefined"
          label="Máximo"
          type="number"
          outlined
          hide-details
          dense
          class="managrx-input mr-2"
          readonly
          filled
        ></v-text-field>
        <v-text-field
          :class="getClass(rowNew.percent == rowOld.percent)"
          :value="rowNew.percent * 100"
          v-if="rowNew.percent !== undefined"
          label="(0-100)%"
          append-icon="mdi-percent-outline"
          type="number"
          outlined
          hide-details
          dense
          class="managrx-input mr-2"
          readonly
          filled
        ></v-text-field>
      </div>
      <div v-if="isValidRowOld" class="d-flex flex-row mt-3">
        <span class="overline grey--text">Valor Anterior</span>
      </div>
      <div v-if="isValidRowOld" class="d-flex flex-row mt-3 ms-5">
        <v-text-field
          :class="getClass(rowNew.floor == rowOld.floor)"
          :value="rowOld.floor"
          v-if="rowOld.floor !== undefined"
          label="Mínimo"
          type="number"
          outlined
          dense
          hide-details
          class="managrx-input mr-2"
          readonly
          filled
        ></v-text-field>
        <v-text-field
          :class="getClass(rowNew.ceiling == rowOld.ceiling)"
          :value="rowOld.ceiling"
          v-if="rowOld.ceiling !== undefined"
          label="Máximo"
          type="number"
          outlined
          hide-details
          dense
          class="managrx-input mr-2"
          readonly
          filled
        ></v-text-field>
        <v-text-field
          :class="getClass(rowNew.percent == rowOld.percent)"
          :value="rowOld.percent * 100"
          v-if="rowOld.percent !== undefined"
          label="(0-100)%"
          append-icon="mdi-percent-outline"
          type="number"
          outlined
          hide-details
          dense
          class="managrx-input mr-2"
          readonly
          filled
        ></v-text-field>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.background-muted {
  background-color: #fafafa;
  opacity: 0.3;
}
.v-text-field {
  width: 150px;
  max-width: 300px;
}
.managrx-input {
  font-weight: 400 !important;
}
.rounded-start {
  border-radius: 5px 0px 0px 5px;
}

.rounded-end {
  border-radius: 0px 5px 5px 0px;
}
.light-text-style {
  font-weight: 100 !important;
}
.bold-text-style {
  font-weight: 900 !important;
}
</style>
<script>
export default {
  name: "AbstractVariableShareCropping",
  data() {
    return {
      editMode: false,
      deleteMode: false,
      rowEdits: {},
      qq: "",
    };
  },
  props: {
    rowNew: Object,
    rowOld: Object,
  },

  computed: {
    isValidRowNew() {
      return Object.keys(this.rowNew).length > 0;
    },
    isValidRowOld() {
      return Object.keys(this.rowOld).length > 0;
    },
  },
  methods: {
    getClass(isSameValue) {
      if (this.isValidRowNew && this.isValidRowOld) {
        if (isSameValue) {
          return "light-text-style";
        } else {
          return "bold-text-style";
        }
      } else {
        return "";
      }
    },
  },
};
</script>
