<template>
  <AbstractNewVariableShareCropping
    :activitySliceId="activitySliceId"
    :deleteAllButtonDisabled="isVariableShareCroppingRowsEmpty"
    @rowSave="addVariableShareCropSchedule"
    @variableSharecroppingInterpolationRowSave="interpolationSave"
    @showVariableShareCroppingSchedule="showVariableShareCroppingSchedule"
    @deleteAllVariableShareCroppingSchedules="
      deleteAllVariableShareCroppingSchedules
    "
  />
</template>
<script>
import { postRequest, deleteRequest } from "@/managrx/serverCall";
import AbstractNewVariableShareCropping from "../Abstract/AbstractNewVariableShareCropping.vue";
export default {
  name: "NewVariableShareCropping",
  components: {
    AbstractNewVariableShareCropping,
  },
  props: {
    activitySliceId: Number,
    variableShareCroppingRows: Array,
  },
  data() {
    return {};
  },
  methods: {
    async addVariableShareCropSchedule(payload) {
      let response = await postRequest(
        "/variable_share_crop_schedule",
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("rowSave", response.data);
      }
    },

    async interpolationSave(payload) {
      let failedRequests = [];
      let successfulRequests = [];

      for (let i = 0; i < payload.length; i++) {
        let schedule = payload[i];
        schedule["activitySliceId"] = this.activitySliceId;

        let response = await postRequest(
          "/variable_share_crop_schedule",
          schedule
        );

        if (!response.success) {
          failedRequests.push(response);
        } else {
          successfulRequests.push(response);
        }
      }

      this.$emit(
        "variableSharecroppingInterpolationRowSave",
        failedRequests.length
      );
    },

    async deleteAllVariableShareCroppingSchedules() {
      let failedRequests = [];

      for (
        let index = 0;
        index < this.variableShareCroppingRows.length;
        index++
      ) {
        const row = this.variableShareCroppingRows[index];
        const response = await deleteRequest(
          "/variable_share_crop_schedule/" + row["id"]
        );
        if (response.status !== 200) {
          failedRequests.push(response);
        }
      }

      if (failedRequests.length > 0) {
        alert("Something went wrong please refresh (A delete request failed)");
      }

      this.$emit("variableSharecroppingInterpolationRowSave");
    },
    showVariableShareCroppingSchedule() {
      this.$emit("showVariableShareCroppingSchedule");
    },
  },
  computed: {
    isVariableShareCroppingRowsEmpty() {
      return this.variableShareCroppingRows.length == 0;
    },
  },
};
</script>
