<template>
  <AbstractIncomeBasedCostInput
    :isPct="false"
    :firstLabel="'Entregador'"
    :secondLabel="'Fecha de ingreso'"
    :componentRelevantValue="singleActivitySlice.saleHandOver"
    :incomeDate="singleActivitySlice.incomeDate"
    :activitySliceId="singleActivitySlice.id"
    :isDraft="false"
    @saveClick="saveClick"
  />
</template>

<script>
import AbstractIncomeBasedCostInput from "../Abstract/AbstractIncomeBasedCostInput.vue";
import { putRequest } from "../../managrx/serverCall";
import { mapGetters } from "vuex";

export default {
  name: "HandOverCostInput",
  props: {
    assignedHectares: Number,
    activitySliceId: Number,
    title: String,
    region: Number,
    activity: Number,
    families: Array,
  },
  components: {
    AbstractIncomeBasedCostInput,
  },
  methods: {
    async saveClick(payload) {

      let response = await putRequest("/activity_slice/" + payload.id, {
        saleHandOver: payload.newValue,
      });
      if(!response.success){
        this.$store.dispatch("setNewNotification", response)
      }else{
        this.$emit("rowSave")
      }
    },
  },
  computed: {
    ...mapGetters(["singleActivitySlice"]),
  },
};
</script>
