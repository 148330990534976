<template>
  <div v-if="!this.$store.getters.isPageLoading">
    <v-row>
      <v-col>
        <div>
          <h2 class="overline mt-2 ml-2 grey--text d-flex">
            {{ singleActivitySlice.engagement.summary.campaignName }} - Versión
            {{ singleActivitySlice.engagement.summary.campaignVersion }}
          </h2>

          <h1 class="ml-2" style="font-weight: 200">
            Activity Slice {{ singleActivitySlice.id }}
          </h1>

          <h1 class="ml-2" style="font-weight: 200">
            {{ activityName }}
          </h1>

          <h2 class="ml-2" style="font-weight: 400">
            Establecimiento {{ establishmentName }}
          </h2>
          <h3 class="ml-2" style="font-weight: 400">
            Arrendamientos: {{ engagementRentSummaryText }}
          </h3>

          <div class="d-flex mt-2">
            <v-btn
              x-small
              class="black--text ml-2"
              color="accent white--text"
              elevation="0"
              @click="goToExplorer"
              >Explorer</v-btn
            >
            <v-btn
              x-small
              class="white--text ml-2"
              elevation="0"
              color="info"
              @click="
                $router.push({
                  path: '/engagement/' + singleActivitySlice.engagementId,
                })
              "
              >Engagement</v-btn
            >
          </div>
        </div>
      </v-col>
      <v-col cols="">
        <div v-if="isActivitySliceComplete" class="d-flex flex-row justify-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                class="me-2"
                color="grey lighten-4"
                label
                ><v-icon color="primary" class="ma-0 pa-0 pe-1"
                  >mdi-check-all</v-icon
                >
                <span class="grey--text"> Actividad Completada </span></v-chip
              >
            </template>
            <span>No hay más items proyectados</span>
          </v-tooltip>
        </div>
        <div v-else class="d-flex flex-row justify-end">
          <v-chip class="" color="white " label
            ><v-icon color="grey" class="ma-0 pa-0 pe-1">
              {{ harvestStatusIcon }}
            </v-icon>
            <span class="grey--text"> Cosecha </span></v-chip
          >
          <v-chip class="me-3" color="white " label
            ><v-icon color="grey" class="ma-0 pa-0 pe-1">{{
              freightStatusIcon
            }}</v-icon>
            <span class="grey--text"> Flete </span></v-chip
          >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="me-2"
                v-bind="attrs"
                v-on="on"
                color="grey lighten-4"
                label
                ><v-icon color="grey" class="ma-0 pa-0 pe-1"
                  >mdi-alpha-r-circle</v-icon
                >
                <span class="grey--text"> {{ actualPct }}% </span></v-chip
              >
            </template>
            <span>{{ actualPct }}% realizado</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                class="me-2"
                color="grey lighten-4"
                label
                ><v-icon color="grey" class="ma-0 pa-0 pe-1"
                  >mdi-alpha-n-circle-outline</v-icon
                >
                <span class="grey--text"> {{ scrappedPct }}% </span></v-chip
              >
            </template>
            <span>{{ scrappedPct }}% no realizado</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                class="me-2"
                color="grey lighten-4"
                label
                ><v-icon color="grey" class="ma-0 pa-0 pe-1"
                  >mdi-alpha-p-circle-outline</v-icon
                >
                <span class="grey--text"> {{ plannedPct }}% </span></v-chip
              >
            </template>
            <span>{{ plannedPct }}% proyectado</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!isPageLoading">
      <v-col>
        <v-divider class="mb-4 mt-2"></v-divider>
        <InfoGrid
          :infos="singleActivitySliceInfoGrid"
          infoTitle="KPIs"
          infoTitleClass="grey--text h2"
          valueClass="display-2"
        />
      </v-col>
    </v-row>

    <!-- Assigned Slice Cost Item Section -->
    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-crop </v-icon>
            Insumos</b
          >
        </div>

        <NewSliceCostInput
          :assignedHectares="singleActivitySlice.assignedHectares"
          :activitySliceId="singleActivitySlice.id"
          :campaignEndDate="campaignEndDate"
          :campaignStartDate="campaignStartDate"
          @rowSave="sliceCostInputRowSaveHandler"
          :region="regionId"
          :activity="singleActivitySlice.activityId"
          :families="insumos"
          :sliceInputCostPeriods="sliceInputCostPeriods"
          :campaign="campaignId"
          :sliceProductsList="sliceProductsList"
          :financialPolicies="financialPolicies"
          :interestRates="interestRates"
          :hasFinancing="true"
          :hasPaymentPolicy="false"
        />
      </v-col>
    </v-row>
    <v-row v-if="!isPageLoading">
      <v-col>
        <v-divider></v-divider>
        <div class="mt-4">
          <SliceCostInput
            :row="assignedRow"
            :campaignEndDate="campaignEndDate"
            :campaignStartDate="campaignStartDate"
            :assignedHectares="singleActivitySlice.assignedHectares"
            :activitySliceId="singleActivitySlice.id"
            @rowSave="sliceCostInputRowSaveHandler"
            @confirmDeleteRow="sliceCostInputRowSaveHandler"
            v-for="assignedRow in displayableAssignedInsumos"
            :key="assignedRow.assignedDirectSliceCostItemId"
            :region="regionId"
            :activity="singleActivitySlice.activityId"
            :campaign="campaignId"
            :families="insumos"
            :sliceProductsList="sliceProductsList"
            :sliceInputCostPeriods="sliceInputCostPeriods"
            :financialPolicies="financialPolicies"
            :interestRates="interestRates"
          />
        </div>
        <v-divider></v-divider>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-crop </v-icon>
            Servicios</b
          >
        </div>
        <NewSliceCostInput
          :assignedHectares="singleActivitySlice.assignedHectares"
          :campaign="campaignId"
          :campaignEndDate="campaignEndDate"
          :campaignStartDate="campaignStartDate"
          :activitySliceId="singleActivitySlice.id"
          :region="regionId"
          :activity="singleActivitySlice.activityId"
          :families="servicios"
          @rowSave="sliceCostInputRowSaveHandler"
          :hasPaymentPolicy="true"
          :hasFinancing="false"
          :sliceProductsList="sliceProductsList"
          :sliceInputCostPeriods="sliceInputCostPeriods"
          :financialPolicies="financialPolicies"
          :interestRates="interestRates"
        />
        <v-divider></v-divider>
        <div class="mt-4">
          <SliceCostInput
            :row="assignedRow"
            :campaignEndDate="campaignEndDate"
            :campaignStartDate="campaignStartDate"
            :assignedHectares="singleActivitySlice.assignedHectares"
            :activitySliceId="singleActivitySlice.id"
            @rowSave="sliceCostInputRowSaveHandler"
            @confirmDeleteRow="sliceCostInputRowSaveHandler"
            v-for="assignedRow in displayableAssignedServices"
            :key="assignedRow.assignedDirectSliceCostItemId"
            :region="regionId"
            :hasPaymentPolicy="true"
            :hasFinancing="false"
            :activity="singleActivitySlice.activityId"
            :campaign="campaignId"
            :families="servicios"
            :sliceProductsList="sliceProductsList"
            :sliceInputCostPeriods="sliceInputCostPeriods"
            :financialPolicies="financialPolicies"
            :interestRates="interestRates"
          />
        </div>

        <v-divider></v-divider>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-crop </v-icon>
            Seguimiento y Control</b
          >
        </div>
        <SandGSliceSummary :assignedSandGRows="displayableAssignedSandG" />
        <NewSandGCostInput
          :assignedHectares="singleActivitySlice.assignedHectares"
          :activitySliceId="singleActivitySlice.id"
          :region="regionId"
          :activity="singleActivitySlice.activityId"
          :families="['Seguimiento y Control']"
          :campaign="campaignId"
          :assignedSandGRows="displayableAssignedSandG"
          @rowSave="sliceCostInputRowSaveHandler"
          @showSandGCostRows="showSandGCostRows"
        />
        <v-divider></v-divider>
        <div class="mt-4">
          <SliceCostInput
            v-show="isSandGRowsViewable"
            :row="assignedRow"
            :campaignEndDate="campaignEndDate"
            :campaignStartDate="campaignStartDate"
            :assignedHectares="singleActivitySlice.assignedHectares"
            :activitySliceId="singleActivitySlice.id"
            v-for="assignedRow in displayableAssignedSandG"
            :key="assignedRow.assignedDirectSliceCostItemId"
            :region="regionId"
            :activity="singleActivitySlice.activityId"
            :campaign="campaignId"
            :families="seguimientosYControles"
            :sliceProductsList="sliceProductsList"
            :sliceInputCostPeriods="sliceInputCostPeriods"
            :financialPolicies="financialPolicies"
            :interestRates="interestRates"
            @rowSave="sliceCostInputRowSaveHandler"
            @confirmDeleteRow="sliceCostInputRowSaveHandler"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-crop </v-icon>
            Varios</b
          >
        </div>
        <NewSliceCostInput
          :assignedHectares="singleActivitySlice.assignedHectares"
          :campaign="campaignId"
          :campaignEndDate="campaignEndDate"
          :campaignStartDate="campaignStartDate"
          :activitySliceId="singleActivitySlice.id"
          :region="regionId"
          :activity="singleActivitySlice.activityId"
          :families="varios"
          @rowSave="sliceCostInputRowSaveHandler"
          :hasPaymentPolicy="false"
          :hasFinancing="false"
          :sliceProductsList="sliceProductsList"
          :sliceInputCostPeriods="sliceInputCostPeriods"
          :financialPolicies="financialPolicies"
          :interestRates="interestRates"
        />
      </v-col>
    </v-row>
    <v-row v-if="!isPageLoading">
      <v-col>
        <SliceCostInput
          :row="assignedRow"
          :campaignEndDate="campaignEndDate"
          :campaignStartDate="campaignStartDate"
          :assignedHectares="singleActivitySlice.assignedHectares"
          :activitySliceId="singleActivitySlice.id"
          @rowSave="sliceCostInputRowSaveHandler"
          @confirmDeleteRow="sliceCostInputRowSaveHandler"
          v-for="assignedRow in displayableAssignedVariosCosts"
          :key="assignedRow.assignedDirectSliceCostItemId"
          :region="regionId"
          :activity="singleActivitySlice.activityId"
          :campaign="campaignId"
          :families="varios"
          :sliceProductsList="sliceProductsList"
          :sliceInputCostPeriods="sliceInputCostPeriods"
          :financialPolicies="financialPolicies"
          :interestRates="interestRates"
        />
      </v-col>
    </v-row>
    <v-row v-if="nullInstallment">
      <v-col cols="12">
        <v-divider></v-divider>
        <div class="mt-2">
          <span class="mr-2">
            <b class="grey--text h2">
              <v-icon> mdi-home </v-icon> Arrendamientos</b
            >
          </span>
        </div>

        <div class="d-flex flex-row justify-center my-4">
          <span
            class="text-overline grey--text"
            style="font-size: 18px !important"
          >
            Arrendamineto no establecido
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row v-else-if="!isFixedUsdInstallment">
      <v-col cols="12">
        <v-divider></v-divider>
        <div class="mt-2">
          <span class="mr-2">
            <b class="grey--text h2">
              <v-icon> mdi-home </v-icon> Arrendamientos</b
            >
          </span>
          <span v-if="!hasRentBonus">
            <v-chip small class="info white--text font-weight-medium"
              >Sin bonificación para {{ activityName }}</v-chip
            >
          </span>
          <span v-else>
            <v-chip small class="info white--text font-weight-medium me-2">
              Bonificación {{ activityName }}
            </v-chip>
            <v-chip
              v-for="bonusDetail in parsedRentBonification"
              :key="bonusDetail"
              small
              class="primary white--text font-weight-bold me-2"
            >
              {{ bonusDetail }}</v-chip
            >
          </span>
        </div>
        <div v-if="rentPaymentPlanItems.length">
          <v-data-table
            :headers="rentPaymentPlanKeys"
            :items="rentPaymentPlanItems"
            :items-per-page="15"
            hide-default-footer
            fixed-header
            dense
            class="elevation-0"
            ><template v-slot:top>
              <v-toolbar text elevation="0">
                <v-toolbar-title class="text-subtitle-1 font-weight-light"
                  >Planeadas</v-toolbar-title
                >
              </v-toolbar>
            </template></v-data-table
          >
        </div>
        <div v-else class="d-flex flex-row justify-center my-5">
          <span
            class="text-overline grey--text"
            style="font-size: 18px !important"
          >
            No hay quintales planeados establecidos
          </span>
        </div>

        <div v-if="rentFixedPaymentItems.length">
          <v-data-table
            :headers="rentFixedPaymentKeys"
            :items="rentFixedPaymentItems"
            :items-per-page="15"
            hide-default-footer
            fixed-header
            dense
            class="elevation-0"
            ><template v-slot:top>
              <v-toolbar text elevation="0">
                <v-toolbar-title class="text-subtitle-1 font-weight-light"
                  >Fijaciones</v-toolbar-title
                >
              </v-toolbar>
            </template></v-data-table
          >
        </div>
        <div v-else class="d-flex flex-row justify-center my-5">
          <span
            class="text-overline grey--text"
            style="font-size: 18px !important"
          >
            No hay fijaciones establecidas
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-divider></v-divider>
        <div class="mt-2">
          <span class="mr-2">
            <b class="grey--text h2">
              <v-icon> mdi-home </v-icon> Arrendamientos</b
            >
          </span>
        </div>

        <div class="d-flex flex-row justify-center my-4">
          <span class="text-overline grey--text text-subtitle-2">
            Arrendamiento en DOLARES FIJOS
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mb-2 mt-2">
          <b class="grey--text h2 me-2">
            <v-icon> mdi-barn </v-icon>
            Aparcería Simple</b
          >
          <span v-if="!singleActivitySlice.isFixedSharecroppingHandedOver">
            <v-chip small class="info white--text font-weight-medium"
              >No entrega KG</v-chip
            >
          </span>
          <span v-else>
            <v-chip small class="primary white--text font-weight-medium"
              >Entrega KG</v-chip
            >
          </span>
        </div>
        <SimpleShareCropping
          @rowSave="simpleShareCroppingInputRowSaveHandler"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mb-2 mt-2">
          <b class="grey--text h2 me-2">
            <v-icon> mdi-barn </v-icon>
            Aparcería Variable</b
          >
          <span v-if="!singleActivitySlice.isVariableSharecroppingHandedOver">
            <v-chip small class="info white--text font-weight-medium"
              >No entrega KG</v-chip
            >
          </span>
          <span v-else>
            <v-chip small class="primary white--text font-weight-medium"
              >Entrega KG</v-chip
            >
          </span>
        </div>
        <VariableShareCroppingSummary
          :variableShareCroppingRows="variableShareCropSchedule"
          :activitySliceForwardYield="singleActivitySlice.forwardYield"
        />
        <NewVariableShareCropping
          :activitySliceId="parseInt(this.$route.params.id)"
          :variableShareCroppingRows="variableShareCropSchedule"
          @rowSave="variableShareCroppingInputRowSaveHandler"
          @variableSharecroppingInterpolationRowSave="
            variableShareCroppingInputRowSaveHandler
          "
          @showVariableShareCroppingSchedule="showVariableShareCroppingSchedule"
        />
        <div v-show="isVariableShareCroppingScheduleViewable">
          <VariableShareCropping
            v-for="(row, i) in this.variableShareCropSchedule"
            :key="i"
            :row="row"
            @confirmDeleteRow="variableShareCroppingInputRowSaveHandler"
            @saveClick="variableShareCroppingInputRowSaveHandler"
          />
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mb-2 mt-2">
          <b class="grey--text h2">
            <v-icon> mdi-tractor </v-icon>
            Cosecha
          </b>
        </div>
        <HarvestCostInput
          @putResponseSuccess="harvestCostInputRowSaveHandler"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mb-2 mt-2">
          <b class="grey--text h2">
            <v-icon> mdi-truck </v-icon>
            Fletes</b
          >
        </div>
        <FreightCostInput
          @putResponseSuccess="freightCostInputRowSaveHandler"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mt-2">
          <b class="grey--text h2">
            <v-icon> mdi-weight </v-icon>
            Embolsado/Acondicionamiento</b
          >
        </div>
        <NewTonBasedCost
          :assignedHectares="singleActivitySlice.assignedHectares"
          :activitySliceId="singleActivitySlice.id"
          @rowSave="tonCostInputRowSaveHandler"
        />

        <TonCostInput
          :row="row"
          :assignedHectares="singleActivitySlice.assignedHectares"
          :activitySliceId="singleActivitySlice.id"
          @rowSave="tonCostInputRowSaveHandler"
          @confirmDeleteRow="tonCostInputRowSaveHandler"
          v-for="row in tonCostItemsRows"
          :key="row.assigned_ton_cost_id"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-account-supervisor-circle </v-icon>
            Dirección Técnica</b
          >
        </div>

        <TechnicalDirectionCostInput
          :assignedHectares="singleActivitySlice.assignedHectares"
          :activitySliceId="singleActivitySlice.id"
          :region="regionId"
          :activity="singleActivitySlice.activityId"
          @rowSave="sliceCostInputRowSaveHandler"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-divider></v-divider>
        <div class="ml-2 mt-2 mb-4">
          <b class="grey--text h2">
            <v-icon> mdi-cart-arrow-up </v-icon>
            Gastos de Venta</b
          >
        </div>

        <SaleTaxCostInput
          :assignedHectares="singleActivitySlice.assignedHectares"
          :activitySliceId="singleActivitySlice.id"
          :region="regionId"
          :activity="singleActivitySlice.activityId"
          @rowSave="sliceCostInputRowSaveHandler"
        />
        <ComissionCostInput
          :assignedHectares="singleActivitySlice.assignedHectares"
          :activitySliceId="singleActivitySlice.id"
          :region="regionId"
          :activity="singleActivitySlice.activityId"
          @rowSave="sliceCostInputRowSaveHandler"
        />
        <HandOverCostInput
          :assignedHectares="singleActivitySlice.assignedHectares"
          :activitySliceId="singleActivitySlice.id"
          :region="regionId"
          :activity="singleActivitySlice.activityId"
          @rowSave="sliceCostInputRowSaveHandler"
        />
      </v-col>
    </v-row>
  </div>
</template>

<style>
.v-application .display-1 {
  font-size: 28px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import InfoGrid from "./InfoGrid.vue";
import "vue-json-pretty/lib/styles.css";
import NewSliceCostInput from "./NewSliceCostInput.vue";
import NewTonBasedCost from "./NewTonBasedCost.vue";
import NewSandGCostInput from "./NewSandGCostInput.vue";
import SandGSliceSummary from "../SharedProjectAndDraft/SandGSliceSummary.vue";

import SliceCostInput from "./SliceCostInput.vue";
import TonCostInput from "./TonCostInput.vue";
import HarvestCostInput from "./HarvestCostInput.vue";
import FreightCostInput from "./FreightCostInput.vue";
// import RentInput from "./RentInput.vue";
import SimpleShareCropping from "./SimpleShareCropping.vue";
import VariableShareCropping from "./VariableShareCropping.vue";
import VariableShareCroppingSummary from "../SharedProjectAndDraft/VariableShareCroppingSummary.vue";
import NewVariableShareCropping from "./NewVariableShareCropping.vue";
import TechnicalDirectionCostInput from "./TechnicalDirectionCostInput.vue";
import SaleTaxCostInput from "./SaleTaxCostInput.vue";
import ComissionCostInput from "./ComissionCostInput.vue";
import HandOverCostInput from "./HandOverCostInput.vue";
import { putRequest } from "../../managrx/serverCall";
import {
  insumos,
  servicios,
  varios,
  seguimientosYControles,
  FIXED_USD_CONTRACT,
} from "../../managrx/constants";
import { roundToX } from "../../managrx/utils";

function processAssignedCostItems(sliceCostItems) {
  if (sliceCostItems?.rows == null) {
    return [];
  }

  return sliceCostItems?.rows
    .map((i) => {
      return {
        ...i,
        subTotal: i.itemCost,
        family: i.costItemFamilyName,
        units: i.unitsPerHectare,
        dateUsed: i.dateUsed.split("T")[0],
        datePaid: i.datePaid ? i.datePaid.split("T")[0] : null,
        selectedRate: i.interestRateId
          ? `${i.interestRateName} (${i.rate})`
          : null,
      };
    })
    .sort((a, b) => a.naturalOrder - b.naturalOrder);
}

export default {
  name: "SingleActivitySlicePage1",
  components: {
    InfoGrid,
    SliceCostInput,
    NewSliceCostInput,
    NewTonBasedCost,
    HarvestCostInput,
    FreightCostInput,
    TonCostInput,
    SimpleShareCropping,
    VariableShareCropping,
    NewVariableShareCropping,
    NewSandGCostInput,
    SandGSliceSummary,
    VariableShareCroppingSummary,
    TechnicalDirectionCostInput,
    SaleTaxCostInput,
    ComissionCostInput,
    HandOverCostInput,
  },
  data() {
    return {
      tab: "",
      fluid: true,
      insumos: insumos,
      servicios: servicios,
      seguimientosYControles: seguimientosYControles,
      isVariableShareCroppingScheduleViewable: false,
      isSandGRowsViewable: false,
    };
  },
  beforeCreate() {
    this.$store.dispatch("clearSingleActivitySlice");
  },
  beforeDestroy() {
    this.$store.dispatch("clearSingleActivitySlice");
  },

  computed: {
    ...mapGetters([
      "singleActivitySlice",
      "sliceCostItems",
      "singleActivitySliceInfoGrid",
      "campaignProductsList",
      "variableShareCropSchedule",
      "isPageLoading",
      "sliceProductsList",
      "sliceInputCostPeriods",
      "financialPolicies",
      "interestRates",
    ]),
    isActivitySliceComplete() {
      return this.singleActivitySlice?.summary?.isActivitySliceCompleted;
    },
    harvestStatusIcon() {
      let harvestStatus =
        this.singleActivitySlice?.summary?.isActualHarvestCost;
      if (harvestStatus === null) {
        return "mdi-alpha-p-circle-outline";
      } else if (harvestStatus === true) {
        return "mdi-alpha-r-circle";
      } else {
        return "mdi-alpha-n-circle-outline";
      }
    },
    freightStatusIcon() {
      let freightStatus =
        this.singleActivitySlice?.summary?.isActualFreightCostTn;
      if (freightStatus === null) {
        return "mdi-alpha-p-circle-outline";
      } else if (freightStatus === true) {
        return "mdi-alpha-r-circle";
      } else {
        return "mdi-alpha-n-circle-outline";
      }
    },
    actualPct() {
      return roundToX(this.singleActivitySlice?.summary?.actualPct, 0);
    },
    plannedPct() {
      return roundToX(this.singleActivitySlice?.summary?.plannedPct, 0);
    },
    scrappedPct() {
      return roundToX(this.singleActivitySlice?.summary?.scrappedPct, 0);
    },
    hasRentBonus() {
      return !(
        this.singleActivitySlice?.rentBonus === null ||
        this.singleActivitySlice?.rentBonus?.rentBonusPercent === 0
      );
    },
    parsedRentBonification() {
      // Only one bonus is allowed by AS
      let bonus = this.singleActivitySlice?.rentBonus;
      let parsedBonus = [];
      if (bonus) {
        if (bonus.isUsdHa) {
          parsedBonus.push(`USD / Ha`);
        } else {
          parsedBonus.push(`KG / Ha`);
        }
        parsedBonus.push(`Bonificación ${bonus?.rentBonusPercent * 100}%`);
        parsedBonus.push(`Rinde Bonificado ${bonus?.yieldThreshold}`);
      }
      return parsedBonus;
    },
    nullInstallment() {
      return this.singleActivitySlice?.engagement?.contractType == null;
    },
    isFixedUsdInstallment() {
      return (
        this.singleActivitySlice?.engagement?.contractType ==
        FIXED_USD_CONTRACT.value
      );
    },
    campaignEndDate() {
      return this.singleActivitySlice?.engagement?.summary?.campaignEndDate;
    },
    campaignStartDate() {
      return this.singleActivitySlice?.engagement?.summary?.campaignStartDate;
    },
    regionId() {
      return this.singleActivitySlice?.engagement?.establishment?.regionId;
    },
    sliceCostItemsRows() {
      // Converts snake case to camel to render individual assigned cost componenets
      return processAssignedCostItems(this.sliceCostItems);
    },
    establishmentName() {
      return this?.singleActivitySlice?.engagement?.establishment?.name;
    },
    engagementRentSummaryText() {
      let as = this?.singleActivitySlice;
      let e = this?.singleActivitySlice?.engagement;
      return `${as?.rentPercentApplication * 100}% | ${e?.rentQqHa} qq/ha `;
    },
    activityName() {
      return this.singleActivitySlice?.summary?.activityName;
    },
    tonCostItemsRows() {
      return this.singleActivitySlice?.assignedTonCosts;
    },
    incomeCostItemsRows() {
      return this.singleActivitySlice?.assignedIncomeCosts;
    },
    varios() {
      return varios;
    },
    campaignId() {
      return this.singleActivitySlice?.engagement?.campaign?.id ?? null;
    },
    displayableAssignedInsumos() {
      return this.sliceCostItemsRows
        ?.filter((r) => insumos.some((i) => i === r.costItemFamilyName))
        .sort(
          (a, b) =>
            a?.assignedDirectSliceCostItemId - b?.assignedDirectSliceCostItemId
        );
    },
    displayableAssignedVariosCosts() {
      return this.sliceCostItemsRows
        ?.filter((r) => varios.some((i) => i === r.costItemFamilyName))
        .sort(
          (a, b) =>
            a?.assignedDirectSliceCostItemId - b?.assignedDirectSliceCostItemId
        );
    },

    displayableAssignedServices() {
      return this.sliceCostItemsRows
        ?.filter((r) =>
          // Only Insumos and Labranzas
          servicios.some((s) => s === r.costItemFamilyName)
        )
        .sort(
          (a, b) =>
            a?.assignedDirectSliceCostItemId - b?.assignedDirectSliceCostItemId
        );
    },
    displayableAssignedSandG() {
      return this.sliceCostItemsRows
        ?.filter((r) =>
          // Filter rows that are 'Seguimiento y Control' by matching costItemFamilyName
          seguimientosYControles.some((s) => s === r.costItemFamilyName)
        )
        .sort(
          (a, b) =>
            a?.assignedDirectSliceCostItemId - b?.assignedDirectSliceCostItemId
        );
    },

    rentPaymentPlanKeys() {
      return [
        { text: "Fecha Cuota", value: "paymentDate" },
        { text: "Porcentaje de App", value: "rentPercentApplication" },
        { text: "Cuota Pago Total", value: "engagementQqHa" },
        { text: "Cuota Pago Aplicado", value: "qqHa" },
        { text: "Quintales", value: "quintals" },
        { text: "Monto Pago", value: "paymentAmount" },
        { text: "Monto Pago / ha", value: "paymentAmountByHectare" },
        { text: "Precio en adelanto", value: "forwardPrice" },
      ];
    },
    rentFixedPaymentKeys() {
      return [
        { text: "Fecha Cuota", value: "paymentDate" },
        { text: "Porcentaje de App", value: "rentPercentApplication" },
        { text: "Cuota Pago Total", value: "engagementQqHa" },
        { text: "Cuota Pago Aplicado", value: "qqHa" },
        { text: "Quintales Fijados", value: "quintals" },
        { text: "Monto  ", value: "paymentAmount" },
        { text: "Monto Pago / ha", value: "paymentAmountByHectare" },
        { text: "Precio de Fijación", value: "fixedPrice" },
      ];
    },
    rentFixedPaymentItems() {
      return this.singleActivitySlice?.rentFixedPayment?.items;
    },
    rentPaymentPlanItems() {
      return this.singleActivitySlice?.rentPaymentPlan?.items;
    },
  },
  methods: {
    showSandGCostRows() {
      this.isSandGRowsViewable = !this.isSandGRowsViewable;
    },
    showVariableShareCroppingSchedule() {
      this.isVariableShareCroppingScheduleViewable =
        !this.isVariableShareCroppingScheduleViewable;
    },
    goToEngagement() {
      // Navigate to a single engagement
      let s = { ...this.$store.state.singleActivitySlice };
      this.$store.dispatch("clearSingleActivitySlice");
      this.$router.push({
        path: `/engagement/${s.engagementId}`,
      });
    },
    goToExplorer() {
      // Navigate to a single engagement
      this.$router.push("/CFManager");
    },
    sliceCostInputRowSaveHandler(event) {
      // Handler used to refresh assigned slice cost item rows from database to store
      event;
      this.$store.dispatch("updateSASliceCache", "assignedSCostItems");
      this.$store.dispatch("updateSASliceCache", "summary");
      this.$emit("refreshCashflow");
    },
    tonCostInputRowSaveHandler(event) {
      // Handler used to refresh assigned slice cost item rows from database to store
      event;
      this.$store.dispatch("updateSASliceCache", "assignedTonCosts");
      this.$store.dispatch("updateSASliceCache", "summary");
      this.$emit("refreshCashflow");
    },

    harvestCostInputRowSaveHandler(event) {
      event;
      this.$store.dispatch("updateSASliceCache", "assignedSCostItems");

      this.$store.dispatch("updateSASliceCache", "summary");
      this.$emit("refreshCashflow");
    },
    simpleShareCroppingInputRowSaveHandler(event) {
      event;
      this.$store.dispatch("updateSASliceCache", "assignedSCostItems");
      this.$store.dispatch("updateSASliceCache", "summary");
      this.$emit("refreshCashflow");
    },
    async variableShareCroppingInputRowSaveHandler(event) {
      event;
      await this.$store.dispatch("updateSASliceCache", "variableShareCrop");
      await this.$store.dispatch("updateSASliceCache", "summary");
      await this.$emit("refreshCashflow");
    },
    freightCostInputRowSaveHandler(event) {
      event;
      this.$store.dispatch("updateSASliceCache", "summary");
      this.$emit("refreshCashflow");
    },
    rentCostInputRowSaveHandler(event) {
      console.log(event);
    },
    rentCostInputRowDeleteHandler() {
      this.$store.dispatch("updateSASliceCache", "summary");
      this.$store.dispatch("updateSASliceCache", "rents");
      this.$emit("refreshCashflow");
    },
    async putSingleEngagement(payload) {
      let eId = this.singleActivitySlice.engagement.id;
      let response = await putRequest(`/engagement/${eId}`, payload);
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.rentCostInputRowDeleteHandler();
      }
    },
  },
};
</script>
