<template>
  <AbstractVariableShareCropping
    :dynamicYield="singleActivitySlice.summary.dynamicYield"
    :activitySliceId="singleActivitySlice.id"
    :floor="row.floor"
    :ceiling="row.ceiling"
    :percent="row.percent"
    :actualStatus="row.isActual"
    :variableShareCroppingId="row.id"
    :isDraft="false"
    @updateActualStatus="updateActualStatus"
    @saveClick="saveClick"
    @confirmDeleteRow="confirmDeleteClick"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { putRequest, deleteRequest } from "../../managrx/serverCall";
import AbstractVariableShareCropping from "../Abstract/AbstractVariableShareCropping.vue";
export default {
  name: "VariableShareCropping",
  computed: {
    ...mapGetters(["singleActivitySlice"]),
  },
  components: {
    AbstractVariableShareCropping,
  },
  props: {
    row: Object,
  },
  methods: {
    async updateActualStatus(status) {
      let id = this.row?.id;
      let response = await putRequest(`/variable_share_crop_schedule/${id}`, {
        isActual: status,
      });
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("saveClick");
      }
    },
    async saveClick(event) {
      let payload = {
        floor: event.floor,
        ceiling: event.ceiling,
        percent: event.percent,
      };

      try {
        let response = await putRequest(
          "/variable_share_crop_schedule/" + event.id,
          payload
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
        this.editMode = false;
      } catch (error) {
        let notification = {
          type: "error",
          text: `${error?.response?.data?.error}`,
        };
        this.$store.dispatch("setNewNotification", notification);
        console.log(error);
        console.log(error?.response);
        console.log(error?.response?.data);
      }
      this.$emit("saveClick");
    },
    async confirmDeleteClick(payload) {
      let response = await deleteRequest(
        "/variable_share_crop_schedule/" + payload
      );

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("confirmDeleteRow");
      }
    },
  },
};
</script>
