<template>
  <v-container fluid>
    <v-tabs v-model="tab" class="mb-2">
      <v-tab>Resumen General</v-tab>
      <v-tab @click="resetCartesianProduct">Metrics</v-tab>
      <v-tab>Cashflows</v-tab>
      <v-tab disabled>Eventos de Cosecha</v-tab>
      <v-tab>Globals</v-tab>
      <v-tab @click="refreshLogs()">Historia</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <SingleActivitySlicePage1
          @refreshCashflow="refreshCashflow"
          @fluidClick="() => (this.fluid = !this.fluid)"
        />
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col>
            <AbstractCartesianProductTable
              v-if="!isPageLoading"
              :activityPrice="cartesianProductActivityPrice"
              :dynamicYield="cartesianProductDynamicYield"
              :saleTax="singleActivitySlice.saleTax"
              :saleComission="singleActivitySlice.saleComission"
              :saleHandOver="singleActivitySlice.saleHandOver"
              :totalOtherTonCost="
                singleActivitySlice.summary.otherTonCostTotalByHa
              "
              :nonAutofinanciableCosts="
                singleActivitySlice.summary.nonautofinanciableCostsByHectare
              "
              :freightPrice="singleActivitySlice.freightCostTn"
              :technicalDirection="singleActivitySlice.technicalDirection"
              :varaibleSharecroppingArray="
                singleActivitySlice.variableShareCropSchedule
              "
              :fixedSharecroppingPercent="
                singleActivitySlice.fixedSharecroppingPercent
              "
              :percentHc="singleActivitySlice.harvestPercentCost"
              :maxHc="singleActivitySlice.maxHarvestCost"
              :minHc="singleActivitySlice.minHarvestCost"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col>
            <DenseCashflowTable
              v-if="!isPageLoading"
              :rows="cashflowRows"
              :columns="cashflowColumns"
              :totalHaToolTip="activityTotalHaToolTip"
              :startDate="campaignStartDate"
              :endDate="campaignEndDate"
              :isResourceUtilization="false"
            />
          </v-col>
        </v-row>
        <!-- Unused Resource utilization component -->
        <!-- <v-row>
          <v-col>
            <DenseCashflowTable
              :rows="resourceUtilizationRows"
              :columns="resourceUtilizationColumns"
              :variableParticipationDate="campaignEndDate"
              :isResourceUtilization="true"
            />
          </v-col>
        </v-row> -->
      </v-tab-item>
      <v-tab-item>
        <AbstractHarvestEvent
          :harvestEvents="singleActivitySlice.harvestEvents"
          :assignedHectares="singleActivitySlice.assignedHectares"
          :forwardYield="singleActivitySlice.forwardYield"
          :dynamicYield="dynamicYield"
          @harvestComplete="harvestComplete"
          @saveNewHarvestEvent="saveNewHarvestEvent"
          @saveHarvestEventEdit="saveHarvestEventEdit"
          @deleteHarvestEvent="deleteHarvestEvent"
          @refreshCashflow="refreshCashflow"
        />
      </v-tab-item>
      <v-tab-item>
        <AbstractGlobals
          v-if="!isPageLoading"
          :startDate="campaignStartDate"
          :endDate="campaignEndDate"
          :engagementSummary="engagementSummary"
          :campaignVersion="campaignVersion"
          :singleActivitySliceId="singleActivitySlice.id"
          :assignedHectares="singleActivitySlice.assignedHectares"
          :sowDate="singleActivitySlice.sowDate"
          :harvestDate="singleActivitySlice.harvestDate"
          :incomeDate="singleActivitySlice.incomeDate"
          :forwardYield="singleActivitySlice.forwardYield"
          :isFixedSharecroppingHandedOver="
            singleActivitySlice.isFixedSharecroppingHandedOver
          "
          :isVariableSharecroppingHandedOver="
            singleActivitySlice.isVariableSharecroppingHandedOver
          "
          :rentPercentApplication="singleActivitySlice.rentPercentApplication"
          :insuranceCategories="singleActivitySlice.insuranceCategories"
          :insuranceRate="singleActivitySlice.insuranceRate"
          :insuranceMaterializationDate="
            singleActivitySlice.insuranceMaterializationDate
          "
          :grainQualityFactor="singleActivitySlice.grainQualityFactor"
          @saveEditsToActivitySlice="saveEditsToActivitySlice"
          @deleteSingleActivitySlice="deleteSingleActivitySlice"
        />
      </v-tab-item>
      <v-tab-item>
        <div>
          <v-data-table
            style="overflow-x: hidden !important; max-width: 100%"
            :headers="logHeaders"
            :items="parsedLogs"
            :loading="logsLoading"
            :single-expand="true"
            :expanded.sync="expanded"
            show-expand
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.logAction`]="{ item }">
              <span
                class="font-weight-medium"
                :class="{
                  'red--text': item.logAction == 'Eliminado',
                  'green--text': item.logAction == 'Nuevo',
                  'yellow--text text--darken-2': item.logAction == 'Editado',
                }"
                >{{ item.logAction }}</span
              >
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td
                :colspan="headers.length"
                style="overflow-x: hidden !important"
              >
                <div
                  v-if="item.logOriginTablename == 'activity_slice'"
                  style="overflow-x: scroll"
                >
                  <Globals
                    :rowNew="item.oldNewValue.new"
                    :rowOld="item.oldNewValue.old"
                  />
                </div>
                <div
                  v-else-if="
                    item.logOriginTablename == 'variable_share_crop_schedule'
                  "
                  style="overflow-x: scroll"
                >
                  <VariableShareCropping
                    :rowNew="item.oldNewValue.new"
                    :rowOld="item.oldNewValue.old"
                  />
                </div>
                <div
                  v-else-if="
                    item.logOriginTablename == 'assigned_other_ton_cost_item'
                  "
                  style="overflow-x: scroll"
                >
                  <AssignedOtherTonCostItem
                    :family="item.logResourceName"
                    :rowNew="item.oldNewValue.new"
                    :rowOld="item.oldNewValue.old"
                  />
                </div>
                <div v-else>
                  <AssignedDirectSliceCostItem
                    :sliceProductsList="sliceProductsList"
                    :rowNew="item.oldNewValue.new"
                    :rowOld="item.oldNewValue.old"
                  />
                </div>
              </td>
            </template>
          </v-data-table>
        </div>
        <div class="text-center pt-2">
          <v-pagination
            @input="getPage"
            v-model="page"
            :length="totalPages"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <Globals />
  </v-container>
</template>

<style>
.v-application .display-1 {
  font-size: 28px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import "vue-json-pretty/lib/styles.css";
import SingleActivitySlicePage1 from "../components/Project/SingleActivitySlicePage1.vue";
import DenseCashflowTable from "../components/WIP/DenseCashflowTable.vue";
import AbstractGlobals from "../components/Abstract/AbstractGlobals.vue";
import { unPackAndProcessCashflowKeys } from "../utils";
import AbstractCartesianProductTable from "../components/Abstract/AbstractCartesianProductTable.vue";
import AbstractHarvestEvent from "../components/Abstract/AbstractHarvestEvent.vue";
import {
  putRequest,
  deleteRequest,
  postRequest,
  getRequest,
} from "../managrx/serverCall";
import {
  activityLevelTotalHaToolTip,
  AUDIT_LOG_COLUMNS_ENG_TO_ESP,
  LOG_ACTION_TO_PARSED_LOG_ACTION_ESP,
  LOG_TABLENAME_TO_PARSED_LOG_ESP,
} from "../managrx/constants.js";
import Globals from "../components/Logs/Globals.vue";
import AssignedDirectSliceCostItem from "../components/Logs/AssignedDirectSliceCostItem.vue";
import VariableShareCropping from "../components/Logs/VariableSharecropping.vue";
import AssignedOtherTonCostItem from "../components/Logs/AssignedOtherTonCostItem.vue";
function getOldNewValue(obj1, obj2) {
  let result = [];
  if (Object.keys(obj1).length > 0) {
    result.push(obj1);
  }
  if (Object.keys(obj2).length > 0) {
    result.push(obj2);
  }
  return result;
}
export default {
  name: "SingleActivitySlice",
  components: {
    Globals,
    AssignedOtherTonCostItem,
    VariableShareCropping,
    AssignedDirectSliceCostItem,
    SingleActivitySlicePage1,
    AbstractHarvestEvent,
    DenseCashflowTable,
    AbstractGlobals,
    AbstractCartesianProductTable,
  },
  methods: {
    refreshLogs() {
      this.loadedPages = [];
      this.page = 1;
      this.logs = {};
      this.logsToParse = [];
      this.expanded = [];
      this.totalPages = 0;
      this.totalLogs = 0;
      this.getLogsLength();
      this.getPage();
    },
    async getPage() {
      if (this.loadedPages.includes(this.page)) {
        this.logsToParse = this.logs[this.page];
      } else {
        await this.getNextLogBatch();
        this.logsToParse = this.logs[this.page];
        this.loadedPages.push(this.page);
      }
    },
    async getLogsLength() {
      let result = await getRequest(
        `/module/get/audit-logs/activity/length/${this.$route.params?.id}`
      );
      if (result.success) {
        this.totalLogs = result.data?.numLogs;
        this.totalPages = Math.floor(this.totalLogs / this.logLimit);
      }
    },
    async getNextLogBatch() {
      this.logsLoading = true;
      let skip = (this.page - 1) * this.logLimit;
      let result = await getRequest(
        `/module/get/audit-logs/activity/${this.$route.params?.id}?limit=10&skip=${skip}`
      );
      if (result.success) {
        this.logs[this.page] = result.data?.logs;
      }
      this.logsLoading = false;
    },
    harvestComplete() {
      let notif = {
        success: true,
        data: {
          type: "success",
          text: "Cosecha completada con exito.",
        },
      };
      this.$store.dispatch("setNewNotification", notif);
    },
    async saveNewHarvestEvent(payload) {
      payload.activitySliceId = this.singleActivitySlice?.id;
      let endpoint = "/harvest_event/";
      let result = await postRequest(endpoint, payload);
      if (!result.success) {
        this.errorHandler(result);
      } else {
        this.$store.dispatch("updateSASliceCache", "harvestEvents");
        this.$store.dispatch("updateSASliceCache", "summary");
        this.$emit("refreshCashflow");
      }
    },
    async saveHarvestEventEdit(payload) {
      let endpoint = "/harvest_event/" + payload?.id;
      let result = await putRequest(endpoint, payload?.row);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        this.$store.dispatch("updateSASliceCache", "harvestEvents");
        this.$store.dispatch("updateSASliceCache", "summary");
        this.$emit("refreshCashflow");
      }
    },
    async deleteHarvestEvent(id) {
      let endpoint = "/harvest_event/" + id;
      let result = await deleteRequest(endpoint);

      if (!result.success) {
        this.errorHandler(result);
      } else {
        this.$store.dispatch("updateSASliceCache", "harvestEvents");
        this.$store.dispatch("updateSASliceCache", "summary");
        this.$emit("refreshCashflow");
      }
    },
    resetCartesianProduct() {
      this.cartesianProductDynamicYield =
        this.singleActivitySlice?.summary?.dynamicYield;
      this.cartesianProductActivityPrice =
        this.singleActivitySlice?.summary?.forwardPrice;
    },
    exitRoute() {
      let engagementId = this.singleActivitySlice?.engagementId;
      this.$router.push({
        name: "SingleEngagement",
        params: { id: engagementId },
      });
    },
    async deleteSingleActivitySlice(activitySliceId) {
      let response = await deleteRequest(
        `/module/delete/activity_slice/${activitySliceId}`
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.exitRoute();
      }
    },
    async saveEditsToActivitySlice(payload) {
      const activitySliceId = payload.activitySliceId;
      delete payload.activitySliceId;

      let response = await putRequest(
        `/activity_slice/${activitySliceId}`,
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      }
      this.refreshRoute();
      this.$router.go();
    },
    refreshRoute() {
      this.$store.dispatch(
        "getSingleActivitySlice",
        parseInt(this.$route.params.id)
      );
    },
    refreshCashflow() {
      this.$store.dispatch("getActivitySliceCashflow", {
        activitySliceId: this.$route.params.id,
        startYear: this.cashflowStartYear,
        startMonth: this.cashflowStartMonth,
        endYear: this.cashflowEndYear,
        endMonth: this.cashflowEndMonth,
      });
    },
  },
  data() {
    return {
      expanded: [],
      logsLoading: true,
      logLimit: 10,
      totalLogs: 0,
      totalPages: 0,
      page: 1,
      loadedPages: [],
      logsToParse: [],
      logs: {},
      logHeaders: [
        { text: "", value: "data-table-expand", class: "primary white--text" },

        {
          text: "Accion",
          value: "logAction",
          class: "primary white--text",
        },
        {
          text: "Categoria",
          value: "logResourceName",
          class: "primary white--text",
        },
        {
          text: "Fecha",
          value: "logDate",
          class: "primary white--text",
        },
        {
          text: "Hora",
          value: "logTime",
          class: "primary white--text",
        },

        {
          text: "Usuario",
          value: "logActionUsername",
          class: "primary white--text",
        },
      ],
      showNewValueCompleteTextId: null,
      logColumnsToEsp: AUDIT_LOG_COLUMNS_ENG_TO_ESP,
      logActionToEsp: LOG_ACTION_TO_PARSED_LOG_ACTION_ESP,
      logTablenameToEsp: LOG_TABLENAME_TO_PARSED_LOG_ESP,
      tab: 0,
      fluid: true,
      editedActivitySlice: {},
      cartesianProductDynamicYield: 0,
      cartesianProductActivityPrice: 0,
    };
  },
  beforeCreate() {
    this.$store.dispatch("clearSingleActivitySlice");
  },
  beforeDestroy() {
    this.$store.dispatch("clearSingleActivitySlice");
  },

  async beforeMount() {
    await Promise.all([
      this.$store.dispatch(
        "getSingleActivitySlice",
        parseInt(this.$route.params.id)
      ),
      this.$store.dispatch("fetchFinancialPolicies"),
      this.$store.dispatch("getActivities"),
      this.$store.dispatch("fetchSliceProductsList"),
      this.$store.dispatch("fetchSliceCostInputPeriods"),
      this.$store.dispatch("fetchInterestRates"),
      this.$store.dispatch("fetchOtherTonCostItems"),
    ]);

    this.editedActivitySlice = {
      ...this.singleActivitySlice,
      assignedHectares: this.singleActivitySlice?.assignedHectares,
      sowDate: this.singleActivitySlice?.sowDate?.split("T")[0],
      incomeDate: this.singleActivitySlice?.incomeDate?.split("T")[0],
      harvestDate: this.singleActivitySlice?.harvestDate?.split("T")[0],
    };

    document.title = this.singleActivitySlice?.activity?.name;
    await this.$store.dispatch("getActivitySliceCashflow", {
      activitySliceId: this.$route.params?.id,
      startYear: this.cashflowStartYear,
      startMonth: this.cashflowStartMonth,
      endYear: this.cashflowEndYear,
      endMonth: this.cashflowEndMonth,
    });
  },

  computed: {
    ...mapGetters([
      "activities",
      "singleActivitySlice",
      "singleActivitySliceInfoGrid",
      "singleActivitySliceCashflow",
      "sliceProductsList",
      "campaignProductsList",
      "variableShareCropSchedule",
      "isPageLoading",
    ]),
    parsedLogs() {
      return this.logsToParse?.map((el) => {
        return {
          ...el,
          logDate: new Date(el?.logDatetime)
            ?.toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[0],

          logTime: new Date(el?.logDatetime)
            ?.toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[1],
          logAction: this.logActionToEsp[el?.logAction],
          logTablename: el?.logOriginTablename,
          logOldNewValueHeaders: [
            ...(Object.keys(el?.oldNewValue?.new).length > 0
              ? Object.keys(el?.oldNewValue?.new)
              : Object.keys(el?.oldNewValue?.old)
            ).map((key) => {
              return {
                text: AUDIT_LOG_COLUMNS_ENG_TO_ESP[key],
                value: key,
                class: "mx-5 px-5",
              };
            }),
          ],
          logOldNewValue: getOldNewValue(
            el?.oldNewValue?.new,
            el?.oldNewValue?.old
          ),
        };
      });
    },
    dynamicYield() {
      return this.singleActivitySlice?.summary?.dynamicYield;
    },
    activityTotalHaToolTip() {
      return activityLevelTotalHaToolTip;
    },
    campaignStartDate() {
      return this.singleActivitySlice?.engagement?.summary?.campaignStartDate;
    },
    campaignEndDate() {
      return this.singleActivitySlice?.engagement?.summary?.campaignEndDate;
    },
    cashflowEndMonth() {
      let date = new Date(this.campaignEndDate);

      return date.getMonth() + 1;
    },
    cashflowStartMonth() {
      let date = new Date(this.campaignStartDate);

      return date.getMonth() + 1;
    },
    cashflowEndYear() {
      let date = new Date(this.campaignEndDate);

      return date.getFullYear();
    },
    cashflowStartYear() {
      let date = new Date(this.campaignStartDate);

      return date.getFullYear();
    },
    engagementSummary() {
      return this.singleActivitySlice?.engagement?.summary;
    },
    campaignVersion() {
      return this.singleActivitySlice?.engagement?.campaign?.version;
    },
    cashflowRows() {
      return this.singleActivitySliceCashflow?.rows;
    },
    cashflowColumns() {
      return unPackAndProcessCashflowKeys(
        this.singleActivitySliceCashflow?.keys
      );
    },
    resourceUtilizationRows() {
      return this.singleActivitySlice?.resourceUtilization?.rows;
    },
    resourceUtilizationColumns() {
      return unPackAndProcessCashflowKeys(
        this.singleActivitySlice?.resourceUtilization?.keys
      );
    },
  },
};
</script>
