import { assignedDirectSliceCostRowValidator } from "../../managrx/propsValidators";

let properties = {
  assignedHectares: Number,
  activitySliceId: Number,
  campaignEndDate: String,
  campaignStartDate: String,
  title: String,
  region: Number,
  activity: Number,
  row: { type: Object, validator: assignedDirectSliceCostRowValidator },
  hasPaymentPolicy: Boolean,
  hasFinancing: Boolean,
  campaign: Number,
  // Array of Strings
  //// ["Semillas","Herbicidas","Insecticidas","Fertilizantes","Fungicidas"]
  families: Array,
  sliceProductsList: Object,
  // Array of Objects
  // [{"id":1,"name":"Barbecho 1"}]
  sliceInputCostPeriods: Array,

  // Array of Objects
  // [{"id":5,"name":"All On Harvest","policyJson":"[[100,\"$harvest_date1\"]]"}]
  financialPolicies: Array,
  // Array of Objects
  // [{"id":1,"name":"Interest A (0.08)","rate":0.08}]
  interestRates: Array,
};

export { properties };
