<template>
  <v-form ref="form" class="pr-2 pl-2">
    <div class="d-flex">
      <v-text-field
        label="Familia"
        outlined
        dense
        filled
        class="managrx-input mr-2"
        :value="costParams.costItemFamilyName"
        readonly
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        outlined
        dense
        label="Fecha de uso"
        v-model="startDate"
        type="date"
        :min="campaignStartDate.split('T')[0]"
        @blur="validateDate(startDate)"
      ></v-text-field>
      <v-autocomplete
        ref="mainSelect"
        :value="select"
        @change="changeSelect"
        open-on-clear
        :rules="required"
        :items="parsedSliceProductList"
        return-object
        item-text="value"
        item-value="idx"
        label="Producto"
        dense
        outlined
        class="managrx-input mr-2"
      ></v-autocomplete>
      <v-text-field
        label="Meses a Considerar"
        outlined
        dense
        class="managrx-input mr-2"
        type="number"
        :rules="monthConsiderationRules"
        v-model.number="costParams.monthsConsideration"
      >
      </v-text-field>

      <v-btn
        depressed
        color="primary white--text"
        class="mr-2"
        @click="saveClick"
        :disabled="assignedSandGRows.length != 0"
      >
        <v-icon> mdi-floppy</v-icon>
      </v-btn>
      <v-btn depressed color="info" class="mr-2" @click="resetForm">
        <v-icon> mdi-restart </v-icon>
      </v-btn>
      <v-btn
        depressed
        color="warning"
        class="mr-2 mb-"
        @click="emitShowSandGCostRows"
        :disabled="assignedSandGRows.length == 0"
      >
        <v-icon> mdi-eye-outline </v-icon>
      </v-btn>
      <v-btn
        depressed
        color="error"
        class="mr-2 mb-7"
        @click="deleteAllSandGRows"
        :disabled="assignedSandGRows.length == 0"
      >
        <v-icon> mdi-trash-can-outline </v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
import { postRequest, deleteRequest } from "@/managrx/serverCall";

import { mapGetters } from "vuex";
import { getMonthRange, roundToX, validateForm } from "../../managrx/utils";
import { NEW_PROJECT_SANDG_FORM_RULES } from "../../managrx/formsFieldsRules";

export default {
  name: "NewSandGCostInput",
  props: {
    assignedHectares: Number,
    activitySliceId: Number,
    title: String,
    region: Number,
    activity: Number,
    families: Array,
    campaign: Number,
    assignedSandGRows: Array,
  },
  computed: {
    ...mapGetters([
      "singleActivitySlice",
      "sliceInputCostPeriods",
      "sliceProductsList",
      "financialPolicies",
      "interestRates",
    ]),
    campaignStartDate() {
      return this.singleActivitySlice?.engagement?.summary?.campaignStartDate;
    },
    parsedSliceProductList() {
      return this.sliceProductsList?.options.filter(this.isSandGCostSelectable);
    },
    required() {
      return [(v) => !!v || "Campo obligatorio"];
    },
    monthConsiderationRules() {
      if (
        !this.costParams.monthsConsideration &&
        this.costParams.monthsConsideration != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = NEW_PROJECT_SANDG_FORM_RULES?.monthsConsideration;
      let detail = validateForm(
        this.costParams.monthsConsideration,
        fieldRules
      );
      return detail;
    },
  },
  methods: {
    validateDate(date) {
      let selectedDate;
      const minDate = new Date(this.campaignStartDate);
      if (new Date(date)) {
        selectedDate = new Date(date);
        if (selectedDate < minDate) {
          this.startDate = this.campaignStartDate.split("T")[0];
        }
      }
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.$refs.form.reset();
    },
    async saveClick() {
      if (this.$refs.form.validate()) {
        if (this.costParams?.listedDirectSliceCostItemId == null) {
          throw "Product not selected!";
        }
        let monthsConsiderationInteger = parseInt(
          this.costParams?.monthsConsideration
        );
        let sd = new Date(this.startDate);
        // Get timezone difference
        let minutesOffSet = sd.getTimezoneOffset();

        // Add timezone difference to get the correct starting date
        sd.setMinutes(sd.getMinutes() + minutesOffSet);

        let d1 = getMonthRange(monthsConsiderationInteger, sd);
        let itemsToInsert = [];

        for (let i = 0; i < d1.length; i++) {
          const d = d1[i];

          let item = {
            activitySliceId: this.singleActivitySlice.id,
            listedDirectSliceCostItemId:
              this.costParams.listedDirectSliceCostItemId,
            costInputPeriodId: 1, // (Sin periodo)
            unitsPerHectare: 1,
            percentApplication: 1 / monthsConsiderationInteger,
            dateUsed: new Date(d).toISOString(),
            datePaid: new Date(d).toISOString(),
            paymentPolicyId: null,
            interestRateId: null,
          };

          itemsToInsert.push(item);
        }

        for (let j = 0; j < itemsToInsert.length; j++) {
          const item = itemsToInsert[j];
          let response = await postRequest(
            "/assigned_direct_slice_cost_item",
            item
          );
          if (!response.success) {
            this.$store.dispatch("setNewNotification", response);
          }
        }
        this.$emit("rowSave", itemsToInsert);
      }
    },
    changeSelect(item) {
      this.costParams.listedDirectSliceCostItemId =
        item.listedDirectSliceCostItemId;

      this.costParams.price = item.price;
      this.costParams.costItemFamilyName = item.costItemFamilyName;
      this.costParams.unitMeasure = item.unitMeasure;

      this.computeSubtotal();
    },
    computeSubtotal() {
      let s =
        this.costParams.units *
        this.costParams.price *
        this.assignedHectares *
        this.costParams.percentApplication;
      this.costParams.subTotal = roundToX(s, 2);
      // \\console.log("computeSubtotal");
      // \\console.log(this.costParams);
      // \\console.log(this.assignedHectares);

      // \\console.log(s);
    },
    isSandGCostSelectable(sliceCostItem) {
      return (
        (sliceCostItem.activityId === this.activity ||
          sliceCostItem.activityId === null) &&
        sliceCostItem.campaignId === this.campaign &&
        this.families.some((f) => f === sliceCostItem.costItemFamilyName)
      );
    },
    async deleteAllSandGRows() {
      for (const assignedDirectDraftSliceCostItem of this.assignedSandGRows) {
        console.log(assignedDirectDraftSliceCostItem);
        let response = await deleteRequest(
          "/assigned_direct_slice_cost_item/" +
            assignedDirectDraftSliceCostItem.assignedDirectSliceCostItemId
        );

        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
      }
      this.$emit("rowSave", null);
    },
    emitShowSandGCostRows() {
      this.$emit("showSandGCostRows");
    },
  },
  mounted() {
    if (this.campaignStartDate) {
      this.startDate = new Date(this.campaignStartDate)
        ?.toISOString()
        ?.split("T")[0];
    }
  },
  data() {
    return {
      select: "",
      fee: "fi",
      startDate: new Date(),
      costParams: {
        costItemFamilyName: "",
        monthsConsideration: 1,
      },
    };
  },
};
</script>
