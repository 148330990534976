<template>
  <div class="pr-2 pl-2">
    <div class="d-flex" v-if="!editMode">
      <div class="mt-1 mr-2">
        <v-tooltip v-if="isActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on"
              >mdi-alpha-r-circle</v-icon
            >
          </template>
          <span>Realizado</span>
        </v-tooltip>
        <v-tooltip v-if="isProyected" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on"
              >mdi-alpha-p-circle-outline</v-icon
            >
          </template>
          <span>Proyectado</span>
        </v-tooltip>
        <v-tooltip v-if="isNotActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :class="{ 'background-muted': isNotActual }"
              class="background-small-muted"
              color=""
              v-bind="attrs"
              v-on="on"
              >mdi-alpha-n-circle-outline</v-icon
            >
          </template>
          <span>No realizado</span>
        </v-tooltip>
      </div>
      <v-text-field
        class="managrx-input mr-2"
        label="Producto/Servicio"
        :class="{ 'background-muted': isNotActual }"
        v-model="row.otherTonCostItemName"
        outlined
        dense
        readonly
        filled
        type="text"
        @click="goToTonCostPanel()"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        label="Precio Tonelada"
        :class="{ 'background-muted': isNotActual }"
        v-model.number="row.priceTn"
        outlined
        dense
        readonly
        filled
        type="number"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        label="(0-100)% App"
        :class="{ 'background-muted': isNotActual }"
        v-model.number="percentApplicationAsInt"
        outlined
        append-icon="mdi-percent-outline"
        dense
        readonly
        filled
        type="number"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        label="Subtotal"
        :class="{ 'background-muted': isNotActual }"
        v-model.number="parsedItemCost"
        outlined
        dense
        readonly
        filled
        type="text"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        label="Fecha de uso"
        v-model="processedRow.dateUsed"
        :class="{ 'background-muted': isNotActual }"
        outlined
        dense
        readonly
        filled
        type="date"
      ></v-text-field>
      <v-btn
        :depressed="true"
        v-if="!deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="editClick"
      >
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="deleteMode"
        :depressed="true"
        color="error"
        class="mr-2 white--text lighten-1"
        @click="confirmDeleteClick"
      >
        Confirmar borrado
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
      <v-btn
        depressed
        color="error  white--text"
        class="mr-2"
        @click="deleteClick"
        v-if="!deleteMode"
      >
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </div>
    <div class="d-flex" v-if="editMode">
      <div class="mr-2 mt-1">
        <v-tooltip v-if="isActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on"
              >mdi-alpha-r-circle</v-icon
            >
          </template>
          <span>Realizado</span>
        </v-tooltip>
        <v-tooltip v-if="isProyected" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on"
              >mdi-alpha-p-circle-outline</v-icon
            >
          </template>
          <span>Proyectado</span>
        </v-tooltip>
        <v-tooltip v-if="isNotActual" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="" v-bind="attrs" v-on="on"
              >mdi-alpha-n-circle-outline</v-icon
            >
          </template>
          <span>No realizado</span>
        </v-tooltip>
      </div>
      <v-select
        ref="costItemSelect"
        v-model="select"
        open-on-clear
        :items="costItems"
        return-object
        item-text="name"
        item-value="id"
        label="Elemento de costo"
        dense
        outlined
        class="managrx-input mr-2"
      ></v-select>

      <v-text-field
        class="managrx-input mr-2"
        label="Precio Tonelada"
        v-model.number="newRow.priceTn"
        outlined
        dense
        type="number"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        label="(0-100)% App"
        append-icon="mdi-percent-outline"
        v-model.number="newRow.percentApplication"
        outlined
        dense
        type="number"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        label="Sub Total"
        v-model="computeSubtotal"
        outlined
        dense
        readonly
        filled
        type="text"
      ></v-text-field>

      <v-text-field
        class="managrx-input mr-2"
        label="Fecha de uso"
        v-model="newRow.dateUsed"
        readonly
        outlined
        filled
        dense
        type="date"
      ></v-text-field>
      <v-select
        outlined
        class="managrx-input mr-2"
        dense
        label="Estado"
        open-on-clear
        persistent-hint
        :hint="
          newRow.isActual !== row.isActual ? 'El estado fue modificado' : ''
        "
        :items="actualsItems"
        v-model="newRow.isActual"
        item-value="value"
        item-text="name"
      >
      </v-select>
      <v-btn
        :depressed="true"
        color="success white--text"
        class="mr-2"
        @click="saveClick"
      >
        <v-icon> mdi-floppy</v-icon>
      </v-btn>
      <v-btn
        :depressed="true"
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
      <v-btn
        v-if="deleteMode"
        :depressed="true"
        color="error"
        class="mr-2 white--text lighten-1"
        @click="confirmDeleteClick"
      >
        Confirmar borrado
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
      <v-btn
        depressed
        color="error  white--text"
        class="mr-2"
        @click="deleteClick"
        v-if="!deleteMode && !editMode"
      >
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 500px;
}
.background-muted {
  background-color: #fafafa;
  opacity: 0.3;
}
.managrx-input {
  font-weight: 400 !important;
}
.rounded-start {
  border-radius: 5px 0px 0px 5px;
}

.rounded-end {
  border-radius: 0px 5px 5px 0px;
}
</style>

<script>
import { roundToX } from "../../managrx/utils";

import { mapGetters } from "vuex";
import { putRequest, deleteRequest } from "../../managrx/serverCall";
import { ACTUALS_ITEMS } from "../../managrx/constants";
export default {
  name: "TonCostInput",
  props: {
    assignedHectares: Number,
    activitySliceId: Number,
    title: String,
    row: Object,
  },
  methods: {
    async updateActualStatus(status) {
      let assignedOtherTonCostItemId = this.row?.assignedOtherTonCostItemId;
      let response = await putRequest(
        `/assigned_other_ton_cost_item/${assignedOtherTonCostItemId}`,
        { isActual: status }
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("rowSave");
      }
    },
    goToTonCostPanel() {
      let routeData = this.$router.resolve({
        name: "OtherTonCostItems",
      });
      window.open(routeData.href, "_blank");
    },
    async saveClick() {
      let payload = {
        otherTonCostItemId: this.select?.id,
        priceTn: this.newRow.priceTn,
        dateUsed: new Date(this.newRow.dateUsed).toISOString(),
        percentApplication: this.percentApplicationAsDecimal,
      };

      if (this.row.isActual !== this.newRow.isActual) {
        payload.isActual = this.newRow.isActual;
      }

      let endpoint =
        "/assigned_other_ton_cost_item/" +
        this.newRow?.assignedOtherTonCostItemId;

      let response = await putRequest(endpoint, payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("rowSave", payload);
      }
      this.backClick();
    },

    editClick() {
      this.newRow = {
        ...this.row,
        dateUsed: this.row.dateUsed.split("T")[0],
        percentApplication: this.percentApplicationAsInt,
      };

      this.select = {
        id: this.newRow.otherTonCostItemId,
        name: this.newRow.otherTonCostItemName,
      };

      this.editMode = true;
    },
    backClick() {
      this.editMode = false;
      this.deleteMode = false;
    },
    deleteClick() {
      this.deleteMode = true;
    },
    async confirmDeleteClick() {
      let endpoint =
        "/assigned_other_ton_cost_item/" + this.row.assignedOtherTonCostItemId;
      let response = await deleteRequest(endpoint);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("rowSave", {});
      }
      this.backClick();
    },
  },
  computed: {
    ...mapGetters([
      "sliceProductsList",
      "sliceInputCostPeriods",
      "singleActivitySlice",
      "otherTonCostItems",
    ]),
    isActual() {
      // true
      return this.row?.isActual === true;
    },
    isProyected() {
      // null it means proyected
      return this.row?.isActual === null;
    },
    isNotActual() {
      // Not proyected nor actual
      return this.row?.isActual === false;
    },
    percentApplicationAsInt() {
      return this.row?.percentApplication * 100;
    },
    percentApplicationAsDecimal() {
      return this.newRow?.percentApplication / 100;
    },
    parsedItemCost() {
      return roundToX(this.row.itemCost, 2).toLocaleString("en-US", {
        timeZone: "America/Argentina/Buenos_Aires",
      });
    },
    fixedShareCroppingValue() {
      return this.singleActivitySlice?.isFixedSharecroppingHandedOver
        ? this.singleActivitySlice?.fixedSharecroppingPercent
        : 0;
    },
    variableShareCroppingValue() {
      return this.singleActivitySlice?.isVariableSharecroppingHandedOver
        ? this.singleActivitySlice?.summary?.variableSharecroppingTotalPercent
        : 0;
    },
    producedTons() {
      return roundToX(
        this.singleActivitySlice?.assignedHectares *
          this.singleActivitySlice?.summary?.dynamicYield *
          (1 / 10),
        2
      );
    },
    ownTons() {
      return roundToX(
        this.producedTons *
          (1 - this.variableShareCroppingValue - this.fixedShareCroppingValue),
        2
      );
    },
    computeSubtotal() {
      return roundToX(
        this.ownTons * this.newRow.priceTn * this.percentApplicationAsDecimal,
        2
      ).toLocaleString("en-US", {
        timeZone: "America/Argentina/Buenos_Aires",
      });
    },
    processedRow() {
      return {
        dateUsed: this.row.dateUsed.split("T")[0],
      };
    },
  },
  async mounted() {
    this.costItems = [...this.otherTonCostItems];
  },
  data() {
    return {
      actualsItems: ACTUALS_ITEMS,
      select: null,
      itemSubtotal: 0,
      editMode: false,
      deleteMode: false,
      selectedPeriod: null,
      newRow: {},
    };
  },
};
</script>
