<template>
  <AbstractSimpleShareCropping
    :assignedHectares="singleActivitySlice.assignedHectares"
    :dynamicYield="singleActivitySlice.summary.dynamicYield"
    :fixedSharecroppingPercent="singleActivitySlice.fixedSharecroppingPercent"
    :isDraft="false"
    @rowSave="saveClick"
  />
</template>

<script>
import AbstractSimpleShareCropping from "../Abstract/AbstractSimpleShareCropping.vue";
import { mapGetters } from "vuex";
import { putRequest } from "../../managrx/serverCall";
export default {
  name: "SimpleShareCropping",
  props: {
    activitySliceId: Number,
    assignedHectares: Number,
    dynamicYield: Number,
    fixedSharecroppingPercent: Number,
    fixedSharecroppingQqHa: Number,
  },
  components: {
    AbstractSimpleShareCropping,
  },
  methods: {
    async saveClick(payload) {
      let response = await putRequest(
        `/activity_slice/${this.singleActivitySlice.id}`,
        payload
      );
      if(!response.success){
        this.$store.dispatch("setNewNotification", response)
      }else {
        this.$emit("rowSave", {});
      }
        this.editMode = false;
    },
  },
  computed: {
    ...mapGetters(["singleActivitySlice"]),
  },
};
</script>
