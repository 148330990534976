<template>
  <div class="pr-2 pl-2 my-1">
    <div class="d-flex flex-column align-start">
      <div v-if="isValidRowNew" class="d-flex flex-row">
        <span class="overline grey--text">Valor Nuevo</span>
      </div>
      <div v-if="isValidRowNew" class="d-flex flex-row mt-3 ms-5">
        <v-text-field
          class="managrx-input mr-2"
          label="Producto/Servicio"
          :class="getClass(family == family)"
          :value="family"
          outlined
          dense
          hide-details
          readonly
          filled
          type="text"
        ></v-text-field>
        <v-text-field
          class="managrx-input mr-2"
          label="Precio Tonelada"
          :class="getClass(rowNew.priceTn == rowOld.priceTn)"
          :value="rowNew.priceTn"
          outlined
          dense
          hide-details
          readonly
          filled
          type="number"
        ></v-text-field>
        <v-text-field
          class="managrx-input mr-2"
          label="(0-100)% App"
          :class="
            getClass(rowNew.percentApplication == rowOld.percentApplication)
          "
          :value="rowNew.percentApplication * 100"
          outlined
          append-icon="mdi-percent-outline"
          dense
          hide-details
          readonly
          filled
          type="number"
        ></v-text-field>
        <v-select
          outlined
          class="managrx-input mr-2"
          dense
          label="Estado"
          open-on-clear
          persistent-hint
          filled
          hide-details
          readonly
          :items="actualsItems"
          v-model="rowNew.isActual"
          item-value="value"
          item-text="name"
          v-if="rowNew.isActual !== undefined"
        >
        </v-select>
      </div>
      <div v-if="isValidRowOld" class="d-flex flex-row mt-3">
        <span class="overline grey--text">Valor Anterior</span>
      </div>
      <div v-if="isValidRowOld" class="d-flex flex-row mt-3 ms-5">
        <v-text-field
          class="managrx-input mr-2"
          label="Producto/Servicio"
          :class="getClass(family == family)"
          :value="family"
          outlined
          dense
          hide-details
          readonly
          filled
          type="text"
        ></v-text-field>
        <v-text-field
          class="managrx-input mr-2"
          label="Precio Tonelada"
          :class="getClass(rowNew.priceTn == rowOld.priceTn)"
          :value="rowOld.priceTn"
          outlined
          dense
          hide-details
          readonly
          filled
          type="number"
        ></v-text-field>
        <v-text-field
          class="managrx-input mr-2"
          label="(0-100)% App"
          :class="
            getClass(rowNew.percentApplication == rowOld.percentApplication)
          "
          :value="rowOld.percentApplication * 100"
          outlined
          append-icon="mdi-percent-outline"
          dense
          hide-details
          readonly
          filled
          type="number"
        ></v-text-field>
        <v-select
          outlined
          class="managrx-input mr-2"
          dense
          label="Estado"
          open-on-clear
          persistent-hint
          filled
          hide-details
          readonly
          :items="actualsItems"
          v-model="rowOld.isActual"
          item-value="value"
          item-text="name"
          v-if="rowOld.isActual !== undefined"
        >
        </v-select>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.background-muted {
  background-color: #fafafa;
  opacity: 0.3;
}
.v-text-field {
  width: 150px;
  max-width: 300px;
}
.managrx-input {
  font-weight: 400 !important;
}
.rounded-start {
  border-radius: 5px 0px 0px 5px;
}

.rounded-end {
  border-radius: 0px 5px 5px 0px;
}
.light-text-style {
  font-weight: 100 !important;
}
.bold-text-style {
  font-weight: 900 !important;
}
</style>

<script>
import { ACTUALS_ITEMS } from "../../managrx/constants";
export default {
  name: "TonCostInput",
  props: {
    family: String,
    rowNew: Object,
    rowOld: Object,
  },
  methods: {
    getClass(isSameValue) {
      if (this.isValidRowNew && this.isValidRowOld) {
        if (isSameValue) {
          return "light-text-style";
        } else {
          return "bold-text-style";
        }
      } else {
        return "";
      }
    },
    getDate(dateIsoStr) {
      if (dateIsoStr) {
        return dateIsoStr?.split("T")?.[0];
      }
    },
  },
  computed: {
    isValidRowNew() {
      return Object.keys(this.rowNew).length > 0;
    },
    isValidRowOld() {
      return Object.keys(this.rowOld).length > 0;
    },
    isActual() {
      // true
      return this.row?.isActual === true;
    },
    isProyected() {
      // null it means proyected
      return this.row?.isActual === null;
    },
    isNotActual() {
      // Not proyected nor actual
      return this.row?.isActual === false;
    },
    percentApplicationAsInt() {
      return this.row?.percentApplication * 100;
    },
    percentApplicationAsDecimal() {
      return this.newRow?.percentApplication / 100;
    },
  },
  async mounted() {},
  data() {
    return {
      actualsItems: ACTUALS_ITEMS,
      select: null,
      itemSubtotal: 0,
      editMode: false,
      deleteMode: false,
      selectedPeriod: null,
    };
  },
};
</script>
