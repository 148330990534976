<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="overline mt-2 ml-4 grey--text d-flex">
          {{ campaignRenderName }}
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="ml-4 mb-5">
          <div class="d-flex align-center mb-0 pb-0">
            <h3 class="primary--text" style="font-weight: 400">
              Editar Activity Slice
            </h3>
            <v-btn
              v-if="modifiedValuesNotSaved"
              small
              depressed
              color="info"
              class="ms-5"
              @click="resetValues"
            >
              <v-icon> mdi-restart</v-icon>
            </v-btn>
          </div>
          <div class="ma-0 pa-0" v-if="modifiedValuesNotSaved">
            <span
              class="
                font-weight-normal font-italic
                text--secondary
                caption
                text--muted
              "
              >** Hay valores modificados que todavia no fueron guardados.</span
            >
          </div>
        </div>
        <v-form ref="form" class="d-flex flex-column ml-4 mt-4 pt-4">
          <v-text-field
            label="Hectáreas asignadas"
            dense
            type="number"
            :rules="assignedHectaresRules"
            class="managrx-input mr-2 pb-1"
            v-model.number="singleActivitySlice.assignedHectares"
          ></v-text-field>

          <v-text-field
            label="Mes Siembra"
            dense
            type="date"
            :rules="sowDateRules"
            class="managrx-input mr-2 pb-1"
            v-model="singleActivitySlice.sowDate"
            :min="campaignStartDate"
            :max="campaignEndDate"
            @blur="validateDate(singleActivitySlice.sowDate, 'sowDate')"
          ></v-text-field>

          <v-text-field
            label="Mes Cosecha"
            dense
            type="date"
            :rules="harvestDateRules"
            class="managrx-input mr-2 pb-1"
            v-model="singleActivitySlice.harvestDate"
            :min="campaignStartDate"
            :max="campaignEndDate"
            @blur="validateDate(singleActivitySlice.harvestDate, 'harvestDate')"
          ></v-text-field>

          <v-text-field
            label="Mes Ingreso"
            dense
            type="date"
            class="managrx-input mr-2 pb-1"
            :rules="incomeDateRules"
            v-model="singleActivitySlice.incomeDate"
            :min="campaignStartDate"
            :max="campaignEndDate"
            @blur="validateDate(singleActivitySlice.incomeDate, 'incomeDate')"
          ></v-text-field>

          <v-text-field
            label="Rinde esperado"
            dense
            type="number"
            class="managrx-input mr-2 pb-1"
            :rules="forwardYieldRules"
            v-model.number="singleActivitySlice.forwardYield"
          ></v-text-field>
          <v-select
            label="Aparcería Fija"
            :items="[
              { name: 'Entrega KG', value: true },
              { name: 'No entrega KG', value: false },
            ]"
            item-text="name"
            item-value="value"
            dense
            type="number"
            class="managrx-input mr-2 pb-1"
            v-model="singleActivitySlice.isFixedSharecroppingHandedOver"
          ></v-select>
          <v-select
            label="Aparcería Variable"
            :items="[
              { name: 'Entrega KG', value: true },
              { name: 'No entrega KG', value: false },
            ]"
            item-text="name"
            item-value="value"
            dense
            type="number"
            class="managrx-input mr-2 pb-1"
            v-model="singleActivitySlice.isVariableSharecroppingHandedOver"
          ></v-select>
          <v-text-field
            label="Arrendamiento (0-100)%"
            dense
            type="number"
            class="managrx-input mr-2 pb-1"
            :rules="rentPercentApplicationRules"
            v-model.number="singleActivitySlice.rentPercentApplication"
          ></v-text-field>
          <v-select
            label="Seguros"
            :items="[
              'Semillas',
              'Herbicidas',
              'Insecticidas',
              'Fertilizantes',
              'Fungicidas',
              'Labranza',
              'Aplicaciones',
              'Seguimiento y Control',
              'Arrendamientos',
              'Varios',
            ]"
            clearable
            v-model="singleActivitySlice.insuranceCategories"
            dense
            class="managrx-input mr-2 pb-1"
            multiple
          >
          </v-select>
          <v-text-field
            label="Seguro (0-100)%"
            dense
            type="number"
            class="managrx-input mr-2 pb-1"
            v-model.number="singleActivitySlice.insuranceRate"
          ></v-text-field>
          <v-text-field
            label="Mes de materialización del seguro"
            dense
            type="date"
            class="managrx-input mr-2 pb-1"
            v-model="singleActivitySlice.insuranceMaterializationDate"
          ></v-text-field>
          <v-text-field
            label="Calidad Grano (0-100)%"
            dense
            type="number"
            class="managrx-input mr-2 pb-1"
            :rules="grainQualityFactorRules"
            v-model.number="singleActivitySlice.grainQualityFactor"
          ></v-text-field>
          <div class="d-flex flex-row align-center justify-center mt-4 pt-4">
            <v-btn
              color="primary white--text"
              class="mb-1 me-4"
              @click="saveEditsToActivitySlice"
              depressed
            >
              <v-icon> mdi-floppy </v-icon></v-btn
            >
            <v-btn color="red white--text mb-1 " @click.stop="dialog = true">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </div>
        </v-form>

        <v-dialog v-model="dialog" max-width="505">
          <v-card>
            <v-card-title class="text-h5">
              ¿Esta seguro que desea eliminar el Borrador de esta Actividad?
            </v-card-title>

            <v-card-text>
              Al apretar Eliminar se borraran todos los datos ingresados para
              esta actividad y no podran ser recuperados.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="green darken-1" text @click="dialog = false">
                Cancelar
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="deleteSingleActivitySlice"
              >
                Eliminar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DRAFT_GLOBALS_RULES } from "../../managrx/formsFieldsRules";
import { validateForm } from "../../managrx/utils";
export default {
  name: "AbstractGlobals",
  props: {
    startDate: String,
    endDate: String,
    engagementSummary: Object,
    campaignVersion: Number,
    singleActivitySliceId: Number,
    assignedHectares: Number,
    sowDate: String,
    harvestDate: String,
    incomeDate: String,
    forwardYield: Number,
    isFixedSharecroppingHandedOver: Boolean,
    isVariableSharecroppingHandedOver: Boolean,
    rentPercentApplication: Number,
    grainQualityFactor: Number,
    insuranceCategories: Array,
    insuranceRate: Number,
    insuranceMaterializationDate: String,
  },
  methods: {
    validateDate(date, key) {
      let selectedDate;
      const minDate = new Date(this.campaignStartDate);
      const maxDate = new Date(this.campaignEndDate);
      if (new Date(date)) {
        selectedDate = new Date(date);
        if (selectedDate < minDate) {
          this.singleActivitySlice[key] = this.campaignStartDate.split("T")[0];
        } else if (selectedDate > maxDate) {
          this.singleActivitySlice[key] = this.campaignEndDate.split("T")[0];
        }
      }
    },
    deleteSingleActivitySlice() {
      this.dialog = false;
      let payload = this.singleActivitySliceId;
      this.$emit("deleteSingleActivitySlice", payload);
    },
    resetValues() {
      this.$refs.form.resetValidation();
      this.singleActivitySlice = {
        id: this.singleActivitySliceId,
        assignedHectares: this.assignedHectares,
        sowDate: this.sowDate?.slice(0, 10),
        harvestDate: this.harvestDate?.slice(0, 10),
        incomeDate: this.incomeDate?.slice(0, 10),
        forwardYield: this.forwardYield,
        isFixedSharecroppingHandedOver: this.isFixedSharecroppingHandedOver,
        isVariableSharecroppingHandedOver:
          this.isVariableSharecroppingHandedOver,
        rentPercentApplication: this.rentPercentApplicationAsInt,
        grainQualityFactor: this.grainQualityFactorAsInt,
        insuranceCategories: this.insuranceCategories,
        insuranceRate: this.insuranceRateAsInt,
        insuranceMaterializationDate: this.insuranceMaterializationDate?.slice(
          0,
          10
        ),
      };
    },
    async saveEditsToActivitySlice() {
      let payload = {
        activitySliceId: this.singleActivitySlice.id,
        grainQualityFactor: this.grainQualityFactorAsDecimal,
        insuranceRate: this.insuranceRateAsDecimal,
        insuranceCategories: this.parsedInsuranceCategories,
        rentPercentApplication: this.rentPercentApplicationAsDecimal,
      };
      let keys = [
        "assignedHectares",
        "sowDate",
        "harvestDate",
        "incomeDate",
        "forwardYield",
        "isFixedSharecroppingHandedOver",
        "isVariableSharecroppingHandedOver",
        "insuranceMaterializationDate",
      ];

      for (let i = 0; i < keys.length; i++) {
        payload[keys[i]] = this.singleActivitySlice[keys[i]];

        if (keys[i].includes("Date")) {
          payload[keys[i]] = new Date(payload[keys[i]]).toISOString();
        }
      }

      this.$emit("saveEditsToActivitySlice", payload);
    },
  },
  computed: {
    parsedInsuranceCategories() {
      return this.singleActivitySlice.insuranceCategories?.length
        ? this.singleActivitySlice.insuranceCategories?.join(",")
        : null;
    },
    insuranceRateAsInt() {
      return this.insuranceRate * 100;
    },
    insuranceRateAsDecimal() {
      return this.singleActivitySlice.insuranceRate / 100;
    },
    grainQualityFactorAsInt() {
      return this.grainQualityFactor * 100;
    },
    grainQualityFactorAsDecimal() {
      return this.singleActivitySlice.grainQualityFactor / 100;
    },
    campaignEndDate() {
      if (this.endDate) {
        return this.endDate.split("T")[0];
      } else {
        return null;
      }
    },
    campaignStartDate() {
      if (this.startDate) {
        return this.startDate.split("T")[0];
      } else {
        return null;
      }
    },
    rentPercentApplicationAsInt() {
      return this.rentPercentApplication * 100;
    },
    rentPercentApplicationAsDecimal() {
      return this.singleActivitySlice.rentPercentApplication / 100;
    },
    modifiedValuesNotSaved() {
      let orginalSingleActivitySlice = {
        id: this.singleActivitySliceId,
        assignedHectares: this.assignedHectares,
        sowDate: this.sowDate?.slice(0, 10),
        harvestDate: this.harvestDate?.slice(0, 10),
        incomeDate: this.incomeDate?.slice(0, 10),
        forwardYield: this.forwardYield,
        isFixedSharecroppingHandedOver: this.isFixedSharecroppingHandedOver,
        isVariableSharecroppingHandedOver:
          this.isVariableSharecroppingHandedOver,
        rentPercentApplication: this.rentPercentApplicationAsInt,
        grainQualityFactor: this.grainQualityFactorAsInt,
        insuranceCategories: this.insuranceCategories,
        insuranceRate: this.insuranceRateAsInt,
        insuranceMaterializationDate: this.insuranceMaterializationDate?.slice(
          0,
          10
        ),
      };
      let singleActivitySliceKeys = Object.keys(this.singleActivitySlice);
      let modifiedValues = singleActivitySliceKeys.map(
        (key) =>
          this.singleActivitySlice[key] == orginalSingleActivitySlice[key]
      );
      if (modifiedValues.every((v) => v === true)) {
        return false;
      }
      return true;
    },
    campaignRenderName() {
      return this.engagementSummary?.campaignName &&
        this.engagementSummary?.campaignVersion
        ? `${this.engagementSummary?.campaignName} - Versión ${this.engagementSummary?.campaignVersion}`
        : "Cargando Campaña";
    },
    assignedHectaresRules() {
      if (
        !this.singleActivitySlice.assignedHectares &&
        this.singleActivitySlice.assignedHectares != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.assignedHectares;
      let detail = validateForm(
        this.singleActivitySlice.assignedHectares,
        fieldRules
      );
      return detail;
    },
    sowDateRules() {
      if (
        !this.singleActivitySlice.sowDate &&
        this.singleActivitySlice.sowDate != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.sowDate;
      let detail = validateForm(this.singleActivitySlice.sowDate, fieldRules);
      return detail;
    },
    harvestDateRules() {
      if (
        !this.singleActivitySlice.harvestDate &&
        this.singleActivitySlice.harvestDate != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.harvestDate;
      let detail = validateForm(
        this.singleActivitySlice.harvestDate,
        fieldRules
      );
      return detail;
    },
    incomeDateRules() {
      if (
        !this.singleActivitySlice.incomeDate &&
        this.singleActivitySlice.incomeDate != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.incomeDate;
      let detail = validateForm(
        this.singleActivitySlice.incomeDate,
        fieldRules
      );
      return detail;
    },
    forwardYieldRules() {
      if (
        !this.singleActivitySlice.forwardYield &&
        this.singleActivitySlice.forwardYield != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.forwardYield;
      let detail = validateForm(
        this.singleActivitySlice.forwardYield,
        fieldRules
      );
      return detail;
    },

    rentPercentApplicationRules() {
      if (
        !this.singleActivitySlice.rentPercentApplication &&
        this.singleActivitySlice.rentPercentApplication != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.rentPercentApplication;
      let detail = validateForm(
        this.singleActivitySlice.rentPercentApplication,
        fieldRules
      );
      return detail;
    },

    grainQualityFactorRules() {
      if (
        !this.singleActivitySlice.grainQualityFactor &&
        this.singleActivitySlice.grainQualityFactor != 0
      ) {
        return ["Campo obligatorio"];
      }
      let fieldRules = DRAFT_GLOBALS_RULES?.grainQualityFactor;
      let detail = validateForm(
        this.singleActivitySlice.grainQualityFactor,
        fieldRules
      );
      return detail;
    },
  },
  mounted() {
    this.singleActivitySlice.id = this.singleActivitySliceId;
    this.singleActivitySlice.assignedHectares = this.assignedHectares;
    this.singleActivitySlice.sowDate = this.sowDate?.slice(0, 10);
    this.singleActivitySlice.harvestDate = this.harvestDate?.slice(0, 10);
    this.singleActivitySlice.incomeDate = this.incomeDate?.slice(0, 10);
    this.singleActivitySlice.forwardYield = this.forwardYield;
    this.singleActivitySlice.rentPercentApplication =
      this.rentPercentApplication;
    this.singleActivitySlice.rentPercentApplication =
      this.rentPercentApplicationAsInt;
    this.singleActivitySlice.grainQualityFactor = this.grainQualityFactorAsInt;
    this.singleActivitySlice.isFixedSharecroppingHandedOver =
      this.isFixedSharecroppingHandedOver;
    this.singleActivitySlice.isVariableSharecroppingHandedOver =
      this.isVariableSharecroppingHandedOver;
    this.singleActivitySlice.insuranceCategories = this.insuranceCategories;
    this.singleActivitySlice.insuranceRate = this.insuranceRateAsInt;
    this.singleActivitySlice.insuranceMaterializationDate =
      this.insuranceMaterializationDate?.slice(0, 10);
  },
  data() {
    return {
      dialog: false,
      singleActivitySlice: {
        id: 0,
        assignedHectares: 0,
        sowDate: "",
        harvestDate: "",
        incomeDate: "",
        forwardYield: 0,
        isFixedSharecroppingHandedOver: false,
        isVariableSharecroppingHandedOver: false,
        rentPercentApplication: 0,
        grainQualityFactor: 0,
        insuranceCategories: [],
        insuranceRate: 0,
        insuranceMaterializationDate: "",
      },
    };
  },
};
</script>
