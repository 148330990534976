<template>
  <AbstractSliceCostInput
    :assignedHectares="assignedHectares"
    :activity="activity"
    title="title"
    :region="region"
    :campaignEndDate="campaignEndDate"
    :campaignStartDate="campaignStartDate"
    :row="row"
    :hasPaymentPolicy="hasPaymentPolicy"
    :hasFinancing="hasFinancing"
    :campaign="campaign"
    :families="families"
    :sliceProductsList="sliceProductsList"
    :sliceInputCostPeriods="sliceInputCostPeriods"
    :financialPolicies="financialPolicies"
    :interestRates="interestRates"
    @updateActualStatus="updateActualStatus"
    @saveClick="saveClickHandler"
    @setNewNotification="setNewNotification"
    @confirmDeleteRow="confirmDeleteClickHandler"
  />
</template>

<script>
import { putRequest, deleteRequest } from "@/managrx/serverCall";
import { properties } from "../sharedProps/sliceCostItem";
import AbstractSliceCostInput from "../Abstract/AbstractSliceCostInput.vue";

export default {
  name: "SliceCostInput",
  components: { AbstractSliceCostInput },
  props: { ...properties },
  methods: {
    async updateActualStatus(status) {
      let assignedDirectSliceCostItemId =
        this.row.assignedDirectSliceCostItemId;
      let response = await putRequest(
        `/assigned_direct_slice_cost_item/${assignedDirectSliceCostItemId}`,
        { isActual: status }
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("rowSave");
      }
    },
    setNewNotification(notification) {
      this.$store.dispatch("setNewNotification", notification);
    },
    async saveClickHandler(payload) {
      let assignedDirectSliceCostItemId =
        this.row.assignedDirectSliceCostItemId;
      let response = await putRequest(
        `/assigned_direct_slice_cost_item/${assignedDirectSliceCostItemId}`,
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("rowSave");
      }
    },
    async confirmDeleteClickHandler(id) {
      let response = await deleteRequest(
        `/assigned_direct_slice_cost_item/${id}`
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("confirmDeleteRow");
      }
    },
  },
};
</script>
